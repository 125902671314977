import { useFaye } from 'src/faye';
import useTimerProgress from 'src/hooks/useTimerProgress';

export const listenForumTimer = (ask_id: number) => {
  const { setTimeProgress } = useTimerProgress();
  useFaye(
    {
      // Listen forum phase timer
      [`/${ask_id}/timer/forum`]: (channel: string, { timeLeft, progress, state }) => {
        setTimeProgress(timeLeft, progress, state);
      }
    },
    [ask_id]
  );
};

export const listenCommentingTimer = (ask_id: number) => {
  const { setTimeProgress } = useTimerProgress();
  useFaye(
    {
      // Listen commenting phase timer
      [`/${ask_id}/timer/commenting`]: (channel: string, { timeLeft, progress, state }) => {
        setTimeProgress(timeLeft, progress, state);
      }
    },
    [ask_id]
  );
};

export const listenDeliberationPhaseTimer = (ask_id: number) => {
  const { setTimeProgress } = useTimerProgress();
  useFaye(
    {
      // Listen deliberation phase timer
      [`/${ask_id}/timer/deliberation`]: (channel: string, { timeLeft, progress, state }) => {
        setTimeProgress(timeLeft, progress, state);
      }
    },
    [ask_id]
  );
};

export const listenFinalRankingPhaseTimer = (ask_id: number) => {
  const { setTimeProgress } = useTimerProgress();
  useFaye(
    {
      // Listen final ranking phase timer
      [`/${ask_id}/timer/final-ranking`]: (channel: string, { timeLeft, progress, state }) => {
        setTimeProgress(timeLeft, progress, state);
      }
    },
    [ask_id]
  );
};

export const listenVoteOnTheProposalPhaseTimer = (ask_id: number) => {
  const { setTimeProgress } = useTimerProgress();
  useFaye(
    {
      // Listen vote on the proposal phase timer
      [`/${ask_id}/timer/vote-on-the-proposal`]: (channel: string, { timeLeft, progress, state }) => {
        setTimeProgress(timeLeft, progress, state);
      }
    },
    [ask_id]
  );
};
