import { FC, PropsWithChildren, useEffect } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

interface GuestGuardProps {}

const GuestGuard: FC<PropsWithChildren<GuestGuardProps>> = ({ children }) => {
  const { isAuthenticated, redirectTo } = useAuth();
  const [searchParams] = useSearchParams();
  const isInviteSession = searchParams.get('invite');
  const inviteLink = isInviteSession ? `/invite/${isInviteSession}` : null;

  if (isAuthenticated) {
    return <Navigate to={redirectTo || inviteLink || '/dashboard'} />;
  }

  return <>{children}</>;
};

export default GuestGuard;
