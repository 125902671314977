import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { withSubscription } from './SubscriptionGuard';
import useSubscription from 'src/hooks/useSubscription';
import SuspenseLoader from '../SuspenseLoader';
import { useCommunities } from 'src/state/hooks';
import { SubscriptionCommunityLimit } from 'src/types/subscriptions';
import useUpgradePlan from 'src/hooks/useUpgradePlan';

interface CommunityLimitGuardProps {}

const CommunityLimitGuard: FC<PropsWithChildren<CommunityLimitGuardProps>> = ({ children }) => {
  const navigate = useNavigate();
  const communities = useCommunities();
  const { subscription, onChecking } = useSubscription();
  const { setOpenDialog, ModalComponent: SubscriptionModalLimit } = useUpgradePlan();

  const community_limit = subscription?.community_limit || SubscriptionCommunityLimit[subscription?.plan];

  useEffect(() => {
    // Redirect to plan page if there is no subscription yet when trying to create a community
    if (!onChecking && !subscription) {
      navigate('/plan');
    }

    // Enforce to update plan if number of communities + 1 reached subscription community limit
    if (!onChecking && (communities || []).length + 1 > community_limit) {
      setOpenDialog(true);
    }
  }, [onChecking, subscription, communities]);

  if (onChecking) {
    return <SuspenseLoader />;
  }

  return (
    <>
      <SubscriptionModalLimit
        message="You have reached the maximum number of community with your subscription. Please upgrade your plan to continue"
        onCancel={() => navigate('/')}
      />
      {children || <Outlet />}
    </>
  );
};

export default withSubscription(CommunityLimitGuard);
