import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NeoState {
  address: string;
  network: string;
  defaultNetwork: string;
  availableNetworks: string[];
  quoteArr: string[];
  balance: {
    [asset: string]: string;
  };
  walletName: string;
  temporarySwitch: number;
  allowAction: boolean;
}

interface NeoUpdateState extends Partial<NeoState> {}

const initialState: NeoState = {
  address: '',
  network: '',
  defaultNetwork: '',
  availableNetworks: [],
  quoteArr: [],
  balance: {},
  walletName: '',
  temporarySwitch: 0,
  allowAction: true
};

export const neoSlice = createSlice({
  name: 'neo',
  initialState,
  reducers: {
    batchUpdate: (state, action: PayloadAction<NeoUpdateState>) => {
      return (state = {
        ...state,
        ...action.payload
      });
    },
    updateTemporarySwitch: (state) => {
      state.temporarySwitch += 1;
    }
  }
});

export const { batchUpdate, updateTemporarySwitch } = neoSlice.actions;

export const reducer = neoSlice.reducer;
export default neoSlice;
