import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
interface AlertProps {
  open: boolean;
  title?: string;
  text?: string;
  textConfirm?: string;
  onConfirm: () => void;
  onCancel: () => void;
}
const Alert = ({ onConfirm, onCancel, open, title, text, textConfirm }: AlertProps) => {
  return (
    <Dialog open={open} onClose={onCancel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      {text && (
        <DialogContent>
          <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{ __html: text }}></DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onConfirm} autoFocus>
          {textConfirm || 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Alert;
