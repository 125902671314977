import React, { FC, PropsWithChildren, createContext } from 'react';
import { NavLink, useLocation, useParams } from 'react-router-dom';
import { MenuItem } from 'src/types/sidebar';
import AskIcon from 'src/components/Svg/AskIcon';
import VoxIcon from 'src/components/Svg/VoxIcon';
import CommunityIcon from 'src/components/Svg/CommunityIcon';
import AnalyticsIcon from 'src/components/Svg/AnalyticsIcon';
import { useAskCommunityId, useCommunity, useAsk } from 'src/state/hooks';
import Badge from '@mui/material/Badge';
import useMobile from 'src/hooks/useMobile';
import HeaderUserbox from 'src/components/Header/Userbox';
import useAuthorization from 'src/hooks/useAuthorization';

interface SidebarMenuContextValue {
  menuItem: MenuItem;
}

export const SidebarMenuContext = createContext<SidebarMenuContextValue>({
  menuItem: null
});

interface SidebarMenuContextProps {
  heading: string;
}

/**
 * Menu logic definition
 *
 * * Community page
 * > if an ask is active: [community, active_ask, active_voxiberation]
 * > if not: [community]
 *
 * * Ask Draft or Pending
 * > only shown: [community]
 *
 * * Active ask with voxiberation
 * > [community, active_ask, active_voxiberation]
 *
 * * Ask Done
 * > [community, ask, voxiberation, analytics]
 *
 * * Profile
 * > [community]
 *
 * @param children
 * @param heading
 * @returns
 */
export const SidebarMenuProvider: FC<PropsWithChildren<SidebarMenuContextProps>> = ({ children, heading }) => {
  const location = useLocation();
  const { ask_id, community_id: c_id } = useParams();
  const ask = useAsk(ask_id);
  const community_id = useAskCommunityId<number>();
  const community = useCommunity(+c_id || community_id);
  const isMobile = useMobile();
  const { authorization } = useAuthorization();

  // can show voxiberation menu only if ask is done
  const isAskDone = ask?.phase === 'done';

  const isAskDraftOrPending = !ask?.phase || ask?.status === 'pending';

  const menuItem = React.useMemo(() => {
    const { id, active_ask_id } = community || {};
    return {
      heading,
      items: [
        {
          name: 'Communities',
          icon: CommunityIcon,
          iconStyle: { width: 43, height: 43 },
          link: ({ children }) => <NavLink to={id ? `/community/${id}` : '/dashboard/communities'}>{children}</NavLink>,
          highlightLinks: ['/community/:id']
        },
        {
          name: 'Ask',
          icon: AskIcon,
          link: ({ children }) => <NavLink to={`/ask/${isAskDone ? ask_id : active_ask_id}/view`}>{children}</NavLink>,
          disabled: (!isAskDone && !active_ask_id) || (heading === 'Ask' && isAskDraftOrPending),
          highlightLinks: ['/ask/:ask_id/view']
        },
        {
          name: 'Voxiberation',
          icon: VoxIcon,
          link: ({ children }) =>
            isAskDone ? (
              <NavLink to={`/ask/${ask_id}/voxiberation`}>{children}</NavLink>
            ) : (
              <NavLink to={`/ask/${isAskDone ? ask_id : active_ask_id}/voxiberation`}>
                {!isAskDone && (
                  <Badge badgeContent={active_ask_id ? 1 : 0} color="primary">
                    {children}
                  </Badge>
                )}
              </NavLink>
            ),
          disabled: (!isAskDone && !active_ask_id) || (heading === 'Ask' && isAskDraftOrPending),
          highlightLinks: ['/ask/:ask_id/voxiberation']
        },
        ...(heading === 'Ask' && ask_id && ask?.phase === 'done'
          ? [
              {
                name: 'Analytics',
                icon: AnalyticsIcon,
                path: 'analytics',
                link: ({ children }) => <NavLink to={`/ask/${ask_id}/analytics`}>{children}</NavLink>,
                highlightLinks: ['/ask/:ask_id/analytics']
              }
            ]
          : []),
        ...(isMobile
          ? [
              {
                name: 'Profile',
                icon: HeaderUserbox,
                path: 'profile',
                link: ({ children }) => <NavLink to={'/dashboard/settings'}>{children}</NavLink>,
                highlightLinks: ['/dashboard/settings']
              }
            ]
          : [])
      ]
    };
  }, [community?.id, location, ask_id, isMobile, authorization]);

  return <SidebarMenuContext.Provider value={{ menuItem }}>{children}</SidebarMenuContext.Provider>;
};
