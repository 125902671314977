import { FC, PropsWithChildren, ReactNode, useState } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { IconButton, Box, Tooltip, Popover, ListItem, Typography, useTheme, Button } from '@mui/material';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

import { styled } from '@mui/material/styles';

const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: -${theme.spacing(1.5)};
  width: 20px;
  height: 20px;

  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiBox-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${theme.colors.alpha.black[70]} !important;
        padding: ${theme.spacing(1, 2)} !important;

        .name-wrapper {
          display: block !important;
        }

        &.Mui-active,
        &:hover {
          background: ${theme.colors.alpha.black[10]} !important;
          color: ${theme.colors.primary.main} !important;
        }
      }
    }  
  }
`
);

const ButtonWrapper = styled(Button)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  &:hover {
    background-color: transparent !important;
  }
`
);

interface SidebarMenuItemProps {
  link: string | ReactNode;
  icon: any;
  open?: boolean;
  active: boolean;
  name: string;
  iconStyle?: any;
  disabled?: boolean;
}

const SidebarMenuItem: FC<PropsWithChildren<SidebarMenuItemProps>> = ({
  children,
  link: Link,
  icon: Icon,
  open: openParent,
  active,
  name,
  iconStyle,
  disabled,
  ...rest
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const activeStyle = {
    backgroundColor: {
      normal: active ? theme.palette.common.white : 'transparent',
      hover: active ? theme.palette.common.white : theme.colors.alpha.silver[30]
    },
    textColor: {
      normal: active ? theme.colors.primary.main : ''
    }
  };

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" {...rest}>
        <Tooltip title={name} disableInteractive placement="right" arrow>
          <IconButton className={clsx({ 'Mui-active': open })} onClick={handleClick}>
            {Icon && <Icon color={active ? theme.colors.primary.main : ''} size={40} />}
            <IndicatorWrapper>{open ? <KeyboardArrowLeftTwoToneIcon /> : <KeyboardArrowRightTwoToneIcon />}</IndicatorWrapper>
          </IconButton>
        </Tooltip>
        <PopoverWrapper
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left'
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}
        >
          {children}
        </PopoverWrapper>
      </ListItem>
    );
  }

  if (!Link) {
    return null;
  }

  const WrappedIcon = (props) => (
    <IconButton
      className={active ? 'Mui-active' : ''}
      sx={{
        opacity: disabled ? '0.5' : 1,
        color: disabled ? 'red' : 'inherit',
        '&:hover': { borderRadius: '20%', backgroundColor: 'transparent' }
      }}
      {...props}
    >
      {Icon && <Icon color={active ? theme.colors.primary.main : theme.palette.grey[900]} sx={{ width: 40, height: 40, ...(iconStyle || {}) }} />}
    </IconButton>
  );

  const TypoName = (
    <Typography
      variant="caption"
      sx={{ textTransform: 'none', fontSize: 10, color: activeStyle.textColor.normal, fontWeight: 'bold' }}
      display="block"
      gutterBottom
    >
      {name}
    </Typography>
  );

  const LinkWrapper =
    typeof Link === 'string' ? (
      <WrappedIcon component={RouterLink} to={Link} />
    ) : (
      // @ts-ignore
      <Link>
        <WrappedIcon />
      </Link>
    );

  const MenuWrapper = () => {
    return (
      <Box display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
        <ListItem component="div" {...rest}></ListItem>
        <WrappedIcon />
        {TypoName}
      </Box>
    );
  };

  if (disabled) {
    return <></>;
  }

  return (
    <Box
      textAlign="center"
      sx={{
        my: 10,
        mx: '10px',
        p: '5px',
        borderRadius: '20%',
        backgroundColor: activeStyle.backgroundColor.normal,
        '&:hover': { backgroundColor: activeStyle.backgroundColor.hover },
        '*': {
          textDecoration: 'none'
        }
      }}
    >
      {typeof Link === 'string' ? (
        <ButtonWrapper
          onClick={() => {
            navigate(Link);
          }}
        >
          <MenuWrapper />
        </ButtonWrapper>
      ) : (
        // @ts-ignore
        <Link>
          <MenuWrapper />
        </Link>
      )}
    </Box>
  );
};

export default SidebarMenuItem;
