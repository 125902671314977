import axios from 'src/utils/axios';
import { Analytics, Ask, PaginatedData, PaginatedQuery, Vote } from 'src/types';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class AskApi extends ApiTokenSupport {
  /**
   * Create a new Ask
   *
   * @param payload
   * @returns
   */
  async create(payload: Ask): Promise<Ask> {
    const resp = await axios.post<Ask>('/api/asks/create', payload, this.withAuthorization());

    return resp.data;
  }

  /**
   * Retrieve a paginated list of Asks in a community
   *
   * @param community_id
   * @param options
   * @returns
   */
  async fetchByCommunityId(community_id: number, options?: PaginatedQuery): Promise<PaginatedData<Ask>> {
    const resp = await axios.post<PaginatedData<Ask>>(`/api/asks/filter/${community_id}`, options || {}, this.withAuthorization());

    return resp.data;
  }

  /**
   * Get ask
   * @param ask_id
   * @returns
   */
  async get(ask_id: number): Promise<Ask> {
    const resp = await axios.get<Ask>(`/api/asks/get-ask/${ask_id}`, this.withAuthorization());

    return resp.data;
  }

  /**
   * Update an ask
   * @param ask_id
   * @param payload
   * @returns
   */
  async update(ask_id: number, payload: Partial<Ask>): Promise<Ask> {
    const resp = await axios.put<Ask>(`/api/asks/${ask_id}`, payload, this.withAuthorization());

    return resp.data;
  }

  async delete(ask_id: number): Promise<boolean> {
    const resp = await axios.delete<boolean>(`/api/asks/${ask_id}`, this.withAuthorization());
    return resp.data;
  }

  async voteOntheProposal(ask_id: number, user_id: number, vote: string): Promise<Vote> {
    const resp = await axios.post<Vote>(`/api/vote`, { ask_id: ask_id, user_id: user_id, vote }, this.withAuthorization());

    return resp.data;
  }

  async checkUserVote(ask_id: number, user_id: number): Promise<Vote> {
    const resp = await axios.post<Vote>(`/api/vote/findOne`, { query: { ask_id: ask_id, user_id: user_id } }, this.withAuthorization());

    return resp.data;
  }

  async handleVoxiberationProcess(
    ask_id: number,
    action?: { restart?: boolean; pause?: boolean; resume?: boolean; stop?: boolean; terminate?: boolean; manually?: boolean }
  ): Promise<string> {
    const resp = await axios.post<string>(`api/voxiberation/process`, { ask_id, ...action }, this.withAuthorization());

    return resp.data;
  }

  async terminateVoxiberation(ask_id: number): Promise<boolean> {
    const resp = await axios.post<boolean>(`api/voxiberation/terminate-voxiberation`, { ask_id }, this.withAuthorization());
    return resp.data;
  }

  downloadReport(ask_id: number) {
    return `${process.env.REACT_APP_BACKEND_URL}/api/report/generate/${ask_id}`;
  }

  async payAsYouGoPrice(community_id: number): Promise<number> {
    const resp = await axios.get<number>(`api/voxiberation/pay-as-you-go-price/${community_id}`, this.withAuthorization());
    return resp.data;
  }

  async generateAnalytics(ask_id: number): Promise<Analytics> {
    const resp = await axios.post<Analytics>(`api/analytics/generate`, { ask_id }, this.withAuthorization());
    return resp.data;
  }
}

export default new AskApi(s.recursive('jwt'));
