import { FC, useCallback, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Typography, Avatar, Badge, useTheme } from '@mui/material';
import MessageTwoTone from '@mui/icons-material/MessageTwoTone';
import { lighten } from '@mui/material';
import Link from '@mui/material/Link';
import { truncate } from '@open-zhy/utils';
import { Notification, NotificationEventType } from 'src/types';
import { useNotificationActions } from 'src/state/hooks';
import { useNavigate } from 'react-router-dom';
import useDiscussion from 'src/hooks/useDiscussion';
import useTimeAgo from 'src/hooks/useTimeAgo';
import { resolveFileUrl } from 'src/utils/file';
import BotIcon from 'src/components/Svg/BotIcon';

interface NotificationItemProps {
  notification: Notification;
}

const BoxWrapper = styled(Box)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    background: lighten(theme.palette.primary.dark, 0.95)
  },
  '&.unread': {
    backgroundColor: lighten(theme.palette.primary.main, 0.8),
    '&:hover': {
      background: lighten(theme.palette.primary.dark, 0.85)
    }
  }
}));

const LinkWrapper = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start'
}));

const BotMessageIcon = styled(BotIcon)(() => ({
  width: 30,
  height: 30,
  padding: 3
}));

const renderAvatar = ({ payload, read }) => {
  const theme = useTheme();

  if (!payload?.sender) {
    return <BotMessageIcon color={`${read ? theme.palette.common.black : theme.colors.primary.main}` as 'inherit'} />;
  }

  const senderAvatar = (
    <Avatar sx={{ width: 26, height: 26, borderWidth: 2, borderColor: 'white', borderStyle: 'solid' }} src={resolveFileUrl(payload.sender.avatar)} />
  );

  return (
    <Badge overlap="circular" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={senderAvatar}>
      {payload.community?.logo ? (
        <Avatar sx={{ width: 42, height: 42 }} alt="Community Logo" src={resolveFileUrl(payload.community.logo)} />
      ) : (
        <BotMessageIcon color={`${read ? theme.palette.common.black : theme.colors.primary.main}` as 'inherit'} />
      )}
    </Badge>
  );
};

const NotificationItem: FC<NotificationItemProps> = ({ notification }) => {
  const { markNotificationAsRead } = useNotificationActions();
  const navigate = useNavigate();
  const theme = useTheme();
  const [payload, setPayload] = useState(null);
  const { openDiscussion } = useDiscussion();

  const { timeAgo } = useTimeAgo();
  const date = new Date(notification.created_at);

  const markAsRead = useCallback(async () => {
    switch (notification.event_type) {
      case NotificationEventType.PRIVATE_CHAT:
        if (payload?.discussion === true) {
          navigate(`/community/${payload.community_id}`);
          setTimeout(() => {
            openDiscussion(true);
          }, 1000);
        }
        break;
      case NotificationEventType.VOXIBERATION:
        navigate(`/ask/${payload?.ask_id}/voxiberation`);
        break;
      case NotificationEventType.REWARDS:
        navigate(`/dashboard/histories`);
        break;
    }

    if (!notification.read) {
      await markNotificationAsRead(notification.id);
    }
  }, [notification, payload]);

  useEffect(() => {
    if (notification.payload && typeof notification.payload === 'string') {
      setPayload(JSON.parse(notification.payload));
    }
  }, [notification.payload]);

  return (
    <BoxWrapper className="container-notification-item" p={2}>
      <LinkWrapper variant="caption" sx={{ textTransform: 'none', textDecoration: 'none', color: 'initial' }} onClick={() => markAsRead()}>
        <Box sx={{ backgroundColor: 'transparent', borderRadius: '100%', display: 'flex' }}>
          {renderAvatar({ payload: payload, read: notification.read })}
        </Box>
        <Box sx={{ marginLeft: '15px', lineHeight: '25px' }}>
          {payload?.sender?.name && <Typography variant="h6">{payload?.sender?.name}</Typography>}
          <Typography fontWeight={500} color={notification.read ? theme.palette.common.black : theme.colors.primary.main} variant="subtitle2">
            {truncate(notification.description, 100)}
          </Typography>
        </Box>
      </LinkWrapper>
      <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
        <Typography variant="subtitle2">{timeAgo(date)}</Typography>
      </Box>
    </BoxWrapper>
  );
};

export default NotificationItem;
