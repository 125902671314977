export enum NotificationEventType {
  VOXIBERATION = 'VOXIBERATION',
  PRIVATE_CHAT = 'PRIVATE CHAT',
  REWARDS = 'REWARDS'
}

export interface Notification {
  id?: number;
  description: string;
  user_id: number;
  payload?: string;
  event_type: NotificationEventType;
  read: boolean;
  created_at?: string;
}
