import { useRef } from 'react';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';

import { Avatar, Badge, Button, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { resolveFileUrl } from 'src/utils/file';
import MemberBadge from 'src/components/MemberBadge';

const UserBoxButton = styled(Button)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  [theme.breakpoints.down('lg')]: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
  }
}));

const AvatarUser = styled(Avatar)(({ theme }) => ({
  borderRadius: theme.spacing(2.5),
  width: theme.spacing(4),
  height: theme.spacing(4),
  border: '1px solid',
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.down('lg')]: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
    marginTop: '-6px',
    fontSize: theme.spacing(1.2)
  }
}));

function HeaderUserbox() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const theme = useTheme();

  const ref = useRef(null);

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={() => navigate('/dashboard/settings')}>
        {user?.avatar ? (
          <>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={user?.vox ? <MemberBadge vox={user?.vox} hideLabel iconSize={10} /> : null}
            >
              <AvatarUser
                variant="rounded"
                alt={user?.firstname}
                src={resolveFileUrl(user?.avatar)}
                imgProps={{ style: { objectFit: 'cover', borderRadius: '50%' } }}
              />
            </Badge>
          </>
        ) : (
          // only facilitator shold pass here as members have a default avatar
          <AvatarUser
            sx={{
              borderColor: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.main,
              fontWeight: 'normal'
            }}
          >
            F
          </AvatarUser>
        )}
        {/* <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user?.name}</UserBoxLabel>
          </UserBoxText>
  </Hidden> */}
        {/* <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
</Hidden> */}
      </UserBoxButton>
    </>
  );
}

export default HeaderUserbox;
