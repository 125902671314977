import { FC, ComponentType, Component as ReactComponent, PropsWithChildren, useEffect } from 'react';
import { Outlet, useSearchParams, useNavigate, useLocation } from 'react-router-dom';
import { SubscriptionProvider } from 'src/contexts/SubscriptionContext';
import useAuthorization from 'src/hooks/useAuthorization';
import useRedirection from 'src/hooks/useRedirection';
import useSubscription from 'src/hooks/useSubscription';

interface SubscriptionGuardProps {}

const SubscriptionGuard: FC<PropsWithChildren<SubscriptionGuardProps>> = ({ children }) => {
  const { subscription, onChecking } = useSubscription();
  const { setRedirection } = useRedirection();
  const [searchParams] = useSearchParams();
  const { authorization } = useAuthorization();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!onChecking && location.pathname !== '/plan') {
      // Only redirect to plan page if current user has subscription under this community scope
      if (authorization && authorization?.canManageSubscription()) {
        const p = searchParams.get('p');

        // Give the ability to fetch loop (refreshing) subscription
        if ((!subscription || !subscription?.is_active) && Boolean(p)) {
          return navigate(`${location.pathname}?p=1`);
        }

        setRedirection(location.pathname);

        // After registration or failed payment use case
        if (!subscription || !subscription?.is_active) {
          return navigate('/plan');
        }

        // Moved under ask overview, on publish case
        /*  if (subscription && subscription.is_active && hasReachedVoxiberationLimit) {
          closeSnackbar();
          enqueueSnackbar(`You have reached the voxiberation limit of your ${subscription?.plan} plan`, {
            variant: 'warning',
            autoHideDuration: 1000 * 5,
            anchorOrigin: { horizontal: 'center', vertical: 'top' }
          });

          navigate('/plan');
        } */
      }
    }
  }, [subscription, authorization, onChecking]);

  return <>{children || <Outlet />}</>;
};

export const withSubscription = <P extends object>(Component: ComponentType<P>) =>
  class WithSubscription extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <SubscriptionProvider>
          <Component {...(props as P)} />
        </SubscriptionProvider>
      );
    }
  };

export default withSubscription(SubscriptionGuard);
