import { FC, PropsWithChildren, createContext, useEffect } from 'react';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

type IntroJsMethodCallbacks = {
  [K in keyof introJs.IntroJs]?: Parameters<introJs.IntroJs[K]>;
};

interface IntroJSContextValue {
  exit: () => void;
  setOptions: (options: introJs.Options) => void;
  start: (callbacks?: Omit<IntroJsMethodCallbacks, 'start'>) => void;
}

export const IntroJSContext = createContext<IntroJSContextValue>({
  setOptions: () => Promise.resolve(),
  start: () => Promise.resolve(),
  exit: () => Promise.resolve()
});

interface IntroJSContextProps {}

export const IntroJSProvider: FC<PropsWithChildren<IntroJSContextProps>> = ({ children }) => {
  let introJS: introJs.IntroJs;

  const init = () => {
    introJS = introJs();
  };

  const setOptions = (options: introJs.Options) => {
    introJS.setOptions(options);
  };

  const start = (callbacks?: Omit<IntroJsMethodCallbacks, 'start'>) => {
    // Dynamically handle giving methods
    for (const [key, cb] of Object.entries(callbacks || {})) {
      if (key && cb?.length > 0) {
        introJS[key](...cb);
      }
    }
    introJS.start();
  };

  const exit = () => {
    introJS.exit();
  };

  useEffect(() => {
    init();

    return () => {
      introJS.exit();
    };
  });

  return <IntroJSContext.Provider value={{ setOptions, start, exit }}>{children}</IntroJSContext.Provider>;
};
