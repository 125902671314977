import { FC, ComponentType, Component as ReactComponent, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Theme } from '@mui/material';
import { Outlet, useLocation } from 'react-router-dom';

import Sidebar from 'src/components/Sidebar';
import Header from 'src/components/Header';
import { SidebarMenuProvider } from 'src/contexts/SidebarMenuContext';
import useMobile from 'src/hooks/useMobile';

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: 120px;
        }
`
);

const MainContent = styled(Box, { shouldForwardProp: (prop) => prop !== 'withHeader' && prop !== 'isVoxiberation' })<{
  withHeader: boolean;
  isVoxiberation?: boolean;
}>(({ theme, withHeader, isVoxiberation }) => ({
  flex: '1 1 auto',
  overflowY: 'auto',
  overflowX: 'hidden',
  marginTop: withHeader ? theme.spacing(6.5) : theme.spacing(0.25),
  ...(isVoxiberation
    ? {
        marginTop: theme.spacing(22),
        marginBottom: theme.spacing(0)
      }
    : {}),
  [theme.breakpoints.down('lg')]: {
    marginTop: withHeader ? theme.spacing(6) : theme.spacing(0.25),
    ...(isVoxiberation
      ? {
          marginTop: theme.spacing(20),
          marginBottom: theme.spacing(22)
        }
      : {})
  }
}));

interface AskLayoutProps {
  withHeader?: boolean;
  isDraft?: boolean;
}

const AskLayout: FC<PropsWithChildren<AskLayoutProps>> = ({ children, withHeader }) => {
  const location = useLocation();
  const isVoxiberation = location.pathname.includes('voxiberation');
  const isAnalytics = location.pathname.includes('analytics');

  const isMobile = useMobile();

  return (
    <>
      <Sidebar />
      <MainWrapper>
        {withHeader && (
          <Header
            sx={(theme: Theme) => ({
              [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(1, 0),
                ...(isMobile && {
                  zIndex: 2
                })
              }
            })}
          />
        )}
        <MainContent
          withHeader
          id="MAINCONTENT"
          isVoxiberation={isVoxiberation}
          sx={(theme) =>
            isMobile && !isVoxiberation
              ? {
                  height: 'calc(100vh - 74px)',
                  [theme.breakpoints.down('lg')]: {
                    marginTop: '74px',
                    ...(isAnalytics ? { marginBottom: theme.spacing(9) } : {})
                  }
                }
              : {}
          }
        >
          {children || <Outlet />}
        </MainContent>
      </MainWrapper>
    </>
  );
};

const withMenuItems = <P extends object>(Component: ComponentType<P>) =>
  class WithMenuItems extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <SidebarMenuProvider heading="Ask">
          <Component {...(props as P)} />
        </SidebarMenuProvider>
      );
    }
  };

export default withMenuItems(AskLayout);
