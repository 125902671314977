import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useSearchParams, useParams } from 'react-router-dom';
import useStepper from 'src/hooks/useStepper';
import { Outlet } from 'react-router-dom';
import withCommunityStepper from 'src/pages/Community/CommunityStepper';
import useCreateCommunity from 'src/hooks/useCreateCommunity';

interface StepCommunityGuardProps {}

const StepCommunityGuard: FC<PropsWithChildren<StepCommunityGuardProps>> = ({ children }) => {
  /* const { setSteps } = useStepper();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { community_id } = useParams();
  const { communityId } = useCreateCommunity();

  const d = searchParams.get('d');

  useEffect(() => {
    if (d) {
      const cId = parseInt(community_id || '0', 10);
      const steps = [
        { key: 1, label: 'community details', cb: { fn: navigate, args: [`/community/create${d ? '?d=true' : ''}`] } },
        { key: 2, label: 'invite members', cb: { fn: navigate, args: [`/community/${cId || communityId || 0}/invite${d ? '?d=true' : ''}`] } }
      ];

      setSteps(steps);
    }
  }, [d, community_id, communityId]); */

  return <>{children || <Outlet />}</>;
};

export default withCommunityStepper(StepCommunityGuard);
