import { Box } from '@mui/material';
import HeaderNotifications from './Notifications';
import { styled } from '@mui/material/styles';

const ButtonWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: theme.spacing(1),
  boxShadow: theme.header.boxShadow,
  [theme.breakpoints.down('lg')]: {
    marginRight: theme.spacing(1)
  }
}));

function HeaderButtons() {
  return (
    <ButtonWrapper>
      <Box sx={{ mx: 0.5 }} component="span">
        <HeaderNotifications />
      </Box>
    </ButtonWrapper>
  );
}

export default HeaderButtons;
