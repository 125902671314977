import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Notification, PaginatedMetadata } from 'src/types';
import { markNotificationAsRead, markAllNotificationAsRead, fetchNotification, readNotificationIcon } from '../actions/notification';

interface NotificationState extends PaginatedMetadata {
  notifications: Notification[];
  processing: boolean;
  countUnread: number;
}

const initialState: NotificationState = {
  notifications: [],
  page: 1,
  pageSize: 5,
  totalPages: 1,
  total: 0,
  processing: false,
  countUnread: 0
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<{ notification: any }>) => {
      return (state = {
        ...state,
        notifications: [action.payload.notification.notification, ...state.notifications],
        countUnread: state.countUnread + 1
      });
    },
    readNotification: (state, action: PayloadAction<{ notification_id: number }>) => {
      return (state = {
        ...state,
        notifications: state.notifications.map((notification) => {
          if (notification.id === action.payload.notification_id) {
            notification.read = true;
          }
          return notification;
        })
      });
    }
  },
  extraReducers: (builder) => {
    builder.addCase(markNotificationAsRead.fulfilled, (state: NotificationState, action: PayloadAction<any, 'fullfiled', any>) => {
      console.log();
      const notification_id = action.meta.arg;
      state.notifications = state.notifications.map((notification) => {
        if (notification.id === notification_id) {
          return {
            ...notification,
            read: true
          };
        }
        return notification;
      });
      state.countUnread = action.payload;
    });
    builder.addCase(markAllNotificationAsRead.fulfilled, (state: NotificationState, action: PayloadAction<any, 'fullfiled', any>) => {
      state.notifications = state.notifications.map((notification) => {
        return {
          ...notification,
          read: true
        };
      });
      state.countUnread = 0;
    });
    builder.addCase(readNotificationIcon.fulfilled, (state: NotificationState, action: PayloadAction<any, 'fullfiled', any>) => {
      state.countUnread = 0;
    });
    builder.addCase(fetchNotification.pending, (state: NotificationState, action: PayloadAction<any, 'fullfiled', any>) => {
      state.processing = true;
    });
    builder.addCase(fetchNotification.fulfilled, (state: NotificationState, action: PayloadAction<any, 'fullfiled', any>) => {
      state.notifications = [...state.notifications, ...action.payload.rows];
      state.total = action.payload.total;
      state.page = action.payload.page;
      state.pageSize = action.payload.pageSize;
      state.totalPages = action.payload.totalPages;
      state.countUnread = action.payload.countUnread;
      state.processing = false;
    });
    builder.addCase(fetchNotification.rejected, (state: NotificationState) => {
      state.processing = false;
    });
  }
});

export const { addNotification, readNotification } = notificationSlice.actions;

export const reducer = notificationSlice.reducer;
export default notificationSlice;
