import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BotIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 43" style={{ color: 'transparent' }}>
      <path
        d="M33.0625 21.4844C33.0625 28.8625 27.0813 34.8437 19.7031 34.8437C12.3249 34.8437 6.34372 28.8625 6.34372 21.4844C6.34372 14.1062 12.3249 8.125 19.7031 8.125C27.0813 8.125 33.0625 14.1062 33.0625 21.4844Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M25.9375 7.23437C25.9375 10.6775 23.1462 13.4687 19.7031 13.4687C16.2599 13.4687 13.4687 10.6775 13.4687 7.23437C13.4687 3.79122 16.2599 1 19.7031 1C23.1462 1 25.9375 3.79122 25.9375 7.23437Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M25.9375 35.7344C25.9375 39.1775 23.1462 41.9688 19.7031 41.9688C16.2599 41.9688 13.4687 39.1775 13.4687 35.7344C13.4687 32.2912 16.2599 29.5 19.7031 29.5C23.1462 29.5 25.9375 32.2912 25.9375 35.7344Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M39 28.0156C39 31.4588 36.2088 34.25 32.7657 34.25C29.3225 34.25 26.5313 31.4588 26.5313 28.0156C26.5313 24.5725 29.3225 21.7812 32.7657 21.7812C36.2088 21.7812 39 24.5725 39 28.0156Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M13.4687 13.7656C13.4687 17.2088 10.6775 20 7.23437 20C3.79122 20 1 17.2088 1 13.7656C1 10.3225 3.79122 7.53124 7.23437 7.53124C10.6775 7.53124 13.4687 10.3225 13.4687 13.7656Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M39 13.7656C39 17.2088 36.2088 20 32.7657 20C29.3225 20 26.5313 17.2088 26.5313 13.7656C26.5313 10.3225 29.3225 7.53124 32.7657 7.53124C36.2088 7.53124 39 10.3225 39 13.7656Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <path
        d="M13.4687 28.0156C13.4687 31.4588 10.6775 34.25 7.23437 34.25C3.79122 34.25 1 31.4588 1 28.0156C1 24.5725 3.79122 21.7812 7.23437 21.7812C10.6775 21.7812 13.4687 24.5725 13.4687 28.0156Z"
        stroke={props.color}
        strokeWidth="1.2"
      />
    </SvgIcon>
  );
};

export default BotIcon;
