import TimeAgo from 'react-timeago';
import moment from 'moment';
// import { isToday, isYesterday } from 'src/utils/dates';

const useTimeAgo = () => {
  const timeAgo = (date: Date | number | string) => {
    // interpret moment as local date
    const refDate = moment.utc(date).local();

    // const asToday = isToday(refDate);
    // const asYesterday = isYesterday(refDate);

    return <TimeAgo date={refDate.toDate()} title={refDate.format('DD.MM.YYYY HH:mm')} className={'text-xxxs xs:text-xxs sm:text-xs md:text-sm'} />;

    // eslint-disable-next-line no-nested-ternary
    /* return asToday ? (
            <TimeAgo
                date={refDate.toDate()}
                title={refDate.format('DD.MM.YYYY HH:mm')}
            />
        ) : asYesterday ? (
            'Yesterday'
        ) : (
            refDate.format('DD.MM.YYYY HH:mm')
        ); */
  };

  return { timeAgo };
};

export default useTimeAgo;
