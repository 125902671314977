import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { SidebarMenuProps } from 'src/types/sidebar';
import { Button, Typography } from '@mui/material';
import { highlightMatch } from 'src/utils/common';
import { useLocation } from 'react-router-dom';
import useSidebarMenu from 'src/hooks/useSidebarMenu';

const BottomWrapper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0, 0),
  zIndex: 999,
  right: 0,
  bottom: 0,
  left: 0,
  boxShadow: theme.header.boxShadow,
  position: 'fixed',
  justifyContent: 'space-between',
  display: 'inline-flex',
  width: '100%',
  borderRadius: theme.spacing(2),
  backgroundColor: theme.colors.alpha.lavenderPale[100],
  a: {
    color: 'inherit',
    textDecoration: 'none',
    padding: '0px 5px'
  },
  '.MuiBadge-badge': {
    top: 'unset',
    bottom: theme.spacing(2.8),
    right: theme.spacing(1),
    fontSize: theme.spacing(1.2)
  }
}));

interface FixedBottomNavigationProps extends SidebarMenuProps {}

const NavigationItem = React.memo(({ item, Icon }: { item: any; Icon: any }) => {
  const theme = useTheme();
  const location = useLocation();
  const isActive = highlightMatch(item.highlightLinks, location.pathname);
  return (
    <Button
      disabled={item.disabled}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        ':hover': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <Icon color={isActive ? theme.colors.primary.main : theme.colors.alpha.black[70]} size={40} />
      <Typography
        variant="caption"
        sx={{ textTransform: 'none', fontSize: theme.spacing(1.2), color: isActive ? theme.colors.primary.main : theme.colors.alpha.black[70] }}
        display="block"
      >
        {item.name}
      </Typography>
    </Button>
  );
});

const FixedBottomNavigation = () => {
  const { menuItem } = useSidebarMenu();

  return (
    <BottomWrapper elevation={3}>
      {(menuItem?.items || []).map(({ icon: Icon, link: Link, ...item }) => {
        if (item.disabled) {
          return null;
        }

        return (
          // @ts-ignore
          <Link key={`bottom_navigation_${item.name}`}>
            <NavigationItem Icon={Icon} item={item} />
          </Link>
        );
      })}
    </BottomWrapper>
  );
};

export default React.memo(FixedBottomNavigation);
