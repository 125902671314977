import type { FC, PropsWithChildren } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

interface RegisterGuardProps {}

const RegisterGuard: FC<PropsWithChildren<RegisterGuardProps>> = ({ children }) => {
  const { isAuthenticated, isNew, redirectTo } = useAuth();
  const [searchParams] = useSearchParams();
  const isInviteSession = searchParams.get('invite');
  const inviteLink = isInviteSession ? `/invite/${isInviteSession}` : null;

  if (isAuthenticated && isNew && !redirectTo && !isInviteSession) {
    return <Navigate to={'/plan'} />;
  }

  if (isAuthenticated) {
    return <Navigate to={redirectTo || inviteLink || '/dashboard'} />;
  }

  return <>{children}</>;
};

export default RegisterGuard;
