import * as React from 'react';
import SubscriptionModalLimit from 'src/components/SubscriptionModalLimit';

const useUpgradePlan = () => {
  const [openDialog, setOpenDialog] = React.useState(false);
  const ModalComponent = (props) => <SubscriptionModalLimit {...props} open={openDialog} />;

  return { setOpenDialog, ModalComponent };
};

export default useUpgradePlan;
