import { matchPath } from 'react-router-dom';

export const loadScript = (src: string): Promise<any> =>
  new Promise((resolve, reject) => {
    const s = document.createElement('script');
    s.src = src;
    s.onload = resolve;
    s.onerror = reject;
    document.head.appendChild(s);
  });

export const capitalizeFirstLetter = (words: string) => {
  return words.charAt(0).toUpperCase() + words.slice(1);
};

export const calculateSumFromObject = (inputString: string): number => {
  const jsonObject = JSON.parse(inputString);
  let sum = 0;

  for (const value of Object.values(jsonObject)) {
    if (typeof value === 'number') {
      sum += value;
    }
  }

  return sum;
};

export const countWords = (text: string) => {
  if (!text) return 0;

  const wordRegex = /[^\s]+/g;
  const matches = text.match(wordRegex);
  return matches ? matches.length : 0;
};

export const textEllipsis = (text: string, length: number) => {
  if (!text) return '';

  if (text.length > length) {
    return `${text.substring(0, length)}...`;
  }
  return text;
};

// Function to merge two arrays of objects based on 'id'
export const mergeArraysBy = (key: string, arr1: any[], arr2: any[]) => {
  const mergedMap = new Map();

  // Add all objects from the first array to the map
  arr1.forEach((item) => mergedMap.set(item[key], { ...item }));

  // Merge objects from the second array into the map
  arr2.forEach((item) => {
    const existing = mergedMap.get(item[key]);
    mergedMap.set(item[key], { ...existing, ...item });
  });

  // Convert the map back to an array
  return Array.from(mergedMap.values());
};

export const isHTML = (text) => {
  const div = document.createElement('div');
  div.innerHTML = text;
  return div.children.length > 0;
};

export const highlightMatch = (highlightLinks, path) => {
  const match = (highlightLinks || []).map((link: string) =>
    link
      ? !!matchPath(
          {
            path: link,
            end: false
          },
          path
        )
      : false
  );
  return (match || []).reduce((acc, v) => acc || v, false);
};
