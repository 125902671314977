import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import { Discussion, DiscussionQuery, Member, UserRole } from 'src/types';
import ApiTokenSupport from './token.support';

class DiscussionApi extends ApiTokenSupport {
  /**
   * Get All Members, by their roles, for discussion.
   */
  async getUserDiscussion(community_id: string, user_role: UserRole, ask_id?: number): Promise<Member[]> {
    const resp = await axios.get<Member[]>(
      `api/discussion/${community_id}/${user_role}${ask_id ? `?ask_id=${ask_id}` : ''}`,
      this.withAuthorization()
    );

    return resp.data;
  }

  /**
   * Add a new discussion
   *
   * @param payload
   * @returns
   */
  async create(payload: Discussion & { ask_id?: number; to_gpt?: boolean }): Promise<Discussion> {
    const resp = await axios.post<Discussion>('/api/discussion/create', payload, this.withAuthorization());
    return resp.data;
  }

  /**
   * Get a discussion history with another user
   *
   * @param payload
   * @returns
   */
  async discussionList(payload: DiscussionQuery): Promise<Discussion[]> {
    const resp = await axios.get<Discussion[]>(
      `api/discussion/discussion-list/${payload.community_id}?to=${payload.to}${payload.page ? '&page=' + payload.page : ''}${
        payload.page ? '&pageSize=' + payload.pageSize : ''
      }${payload.ask_id ? `&ask_id=${payload.ask_id}` : ''}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  /**
   * Set a discussion seen status to true
   *
   * @param community_id
   * @param to
   * @returns
   */
  async seenDiscussion(community_id: number, to: number): Promise<boolean> {
    const resp = await axios.post(
      'api/discussion/toogleSeen',
      {
        community_id,
        to
      },
      this.withAuthorization()
    );

    return resp.data;
  }

  /**
   * Update the user chat status on the database
   */
  async setUserStatus(community_id: number, is_active: boolean): Promise<void> {
    const resp = await axios.post(
      'api/discussion/status',
      {
        community_id,
        is_active
      },
      this.withAuthorization()
    );
  }
}

export default new DiscussionApi(s.recursive('jwt'));
