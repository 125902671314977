import { useLocation, useRoutes } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import routes from './routes';
import useAuth from './hooks/useAuth';
import SplashScreen from 'src/components/SplashScreen';
import { useEffect, useState } from 'react';
import { initWalletApi } from './lib/neo/walletApi';
import { useSnackbar } from 'notistack';
import { userApi } from './api';

import 'react-quill/dist/quill.snow.css';
import './App.css';

const BodyWrapper = styled('div')(({ theme }) => ({
  '@global': {
    body: {
      backgroundColor: theme.general.background
    }
  }
}));

const App = () => {
  const content = useRoutes(routes);
  const { isInitialized, user } = useAuth();

  // We will primarily use the location to keep track the user's route.
  const location = useLocation();

  // In order to minimise API call, this state will be used as intermediate
  // State that will switch between two primary values: "null" or "number":
  const [askID, setAskId] = useState<number>(null);

  useEffect(() => {
    const regex = /\/ask\/(\d+)\/voxiberation/;
    const matches = location.pathname.match(regex);
    // We are mainly intersted by two Routes location :
    // 1 - User is in Voxiberation  / 2 - User is not in Voxiberation
    if (matches) {
      setAskId(parseInt(matches[1], 10));
    } else {
      setAskId(null);
    }
  }, [location.pathname]); // Filter the location each time it changes.

  useEffect(() => {
    (async () => {
      if (user?.id) {
        if (!askID) {
          // We have to set the status of the user to inactive when he is outside the Voxiberation.
          await userApi.updateActiveUser({ user_id: user.id, is_active: false, current_phase: '' });
        }
      }
    })();
  }, [askID, user?.id]); // Each time ask_id changes, we have to update the user's isActive Status.

  const { enqueueSnackbar } = useSnackbar();
  const opts = {
    notifier: enqueueSnackbar
  };

  useEffect(() => {
    initWalletApi(opts);
  }, []);

  return <BodyWrapper>{isInitialized ? content : <SplashScreen />}</BodyWrapper>;
};
export default App;
