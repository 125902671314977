import { FC, PropsWithChildren, useEffect } from 'react';
import { storage as s } from 'src/utils/storage';
import { useLocation, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';

interface AcceptInviteGuardProps {}

const AcceptInviteGuard: FC<PropsWithChildren<AcceptInviteGuardProps>> = ({ children }) => {
  const { setRedirection } = useAuth();
  const location = useLocation();
  const { token } = useParams();
  if (token) {
    s.record('invite_session', token);
  }

  useEffect(() => {
    setRedirection(location.pathname);
  }, []);

  return <>{children}</>;
};

export default AcceptInviteGuard;
