import { useSnackbar } from 'notistack';
import { useFaye } from 'src/faye';
import { updateAskState, getAskById } from 'src/state/actions';
import { useDispatch } from 'src/state/store';
import { Ask } from 'src/types';

export const listenAskPhaseUpdate = (ask: Ask) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useFaye({
    // Listen ask phase update
    [`/${ask.id}/phase/update`]: (channel: string, { phase, restart, phase_status }) => {
      dispatch(getAskById(ask.id))
        .unwrap()
        .then((res: Ask) => {
          dispatch(updateAskState({ ask_id: ask.id, data: { ...res, phase, phase_status } }));
          if (restart) {
            enqueueSnackbar(`The ${phase} has been restarted, the page will be reloaded`, {
              variant: 'warning',
              autoHideDuration: 1500,
              anchorOrigin: { horizontal: 'center', vertical: 'top' }
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000 * 2);
          }
        })
        .catch((reason) => {
          console.log(reason);
        });
    }
  });
};
