import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';
import { StripeInfos, StripeSession, SubscribeInput, Subscription } from 'src/types/subscriptions';

class SubscriptionApi extends ApiTokenSupport {
  async subscribe(input: SubscribeInput): Promise<Subscription> {
    const resp = await axios.post(`/api/subscriptions/subscribe`, input, this.withAuthorization());

    return resp.data;
  }

  async getSubscription(community_id: number): Promise<Subscription> {
    const resp = await axios.get(`/api/subscriptions/fetch/${community_id}`, this.withAuthorization());

    return resp.data;
  }

  async getUserSubscription(user_id: number): Promise<Subscription> {
    const resp = await axios.get(`/api/subscriptions/${user_id}`, this.withAuthorization());

    return resp.data;
  }

  async checkCoupon(code: string): Promise<number> {
    const resp = await axios.get<number>(`/api/subscriptions/promotion-code?code=${code}`, this.withAuthorization());

    return resp.data;
  }

  async countVoxiberation(): Promise<number> {
    const resp = await axios.post(`/api/subscriptions/voxiberation-count`, {}, this.withAuthorization());

    return resp.data;
  }

  async stripeInfos(subscription_id: number): Promise<StripeInfos> {
    const resp = await axios.post<StripeInfos>(`api/stripe-infos/findOne`, { query: { subscription_id } }, this.withAuthorization());
    return resp.data;
  }

  async checkout(plan: string, period?: string): Promise<StripeSession> {
    const resp = await axios.post<StripeSession>(`api/subscriptions/checkout`, { plan, period }, this.withAuthorization());
    return resp.data;
  }

  async billing(upgrade?: boolean): Promise<string> {
    const resp = await axios.post<string>(`api/subscriptions/billing`, { upgrade }, this.withAuthorization());
    return resp.data;
  }
}

export default new SubscriptionApi(s.recursive('jwt'));
