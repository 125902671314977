import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import findIndex from 'lodash.findindex';
import type { Ask, LoadableState, PaginatedMetadata, PaginatedData, PaginatedQuery } from 'src/types';
import { createAsk, fetchAsksByCommunityId, getAskById, updateAsk } from '../actions/asks';

interface AskState extends LoadableState<Ask[]> {
  pagination?: PaginatedMetadata;
  comments: { [key: number]: string };
}

const initialState: AskState = {
  isLoading: false,
  values: [],
  comments: {}
};

const slice = createSlice({
  name: 'asks',
  initialState,
  reducers: {
    storeComment: (state: AskState, action: PayloadAction<{ ask_id: number; comment: string }>) => {
      const { ask_id, comment } = action.payload;
      state.comments[ask_id] = comment;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(createAsk.fulfilled, (state: AskState, action: PayloadAction<Ask>) => {
      state.values.push(action.payload);
    });

    builder.addCase(getAskById.fulfilled, (state: AskState, action: PayloadAction<Ask>) => {
      const i = findIndex(state.values, (v) => v.id === action.payload.id);
      if (i !== -1) {
        state.values[i] = action.payload;
      } else {
        state.values.push(action.payload);
      }
    });

    builder.addCase(fetchAsksByCommunityId.fulfilled, (state: AskState, action: PayloadAction<PaginatedData<Ask>, 'fullfiled', any>) => {
      const { rows, ...pagination } = action.payload;
      state.values = rows;

      state.pagination = pagination;
    });

    builder.addCase(updateAsk.fulfilled, (state: AskState, action: PayloadAction<Ask>) => {
      const i = findIndex(state.values, (v) => v.id === action.payload.id);
      if (i !== -1) {
        state.values[i] = action.payload;
      }
    });
  }
});
export const { storeComment } = slice.actions;
export const reducer = slice.reducer;
export default slice;
