import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';
import { PaymentResult } from 'src/types/subscriptions';

class StripeApi extends ApiTokenSupport {
  async purchase(amount: number, ask_id: number, description: string): Promise<PaymentResult> {
    const resp = await axios.post<PaymentResult>(`api/stripe/purchase`, { amount, ask_id: ask_id, description }, this.withAuthorization());
    return resp.data;
  }
}

export default new StripeApi(s.recursive('jwt'));
