import { useFaye } from 'src/faye';
import useAuth from 'src/hooks/useAuth';
import { userApi } from 'src/api';

import { useDispatch } from 'react-redux';
import { addNotification } from 'src/state/slices/notification';
import { NotificationEventType } from 'src/types';

export const listenNewNotificationMessage = () => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { id: user_id } = user;

  useFaye(
    {
      // Listen for notification message
      [`/${user_id}/new-notification/message`]: async (channel: string, data: any) => {
        dispatch(addNotification({ notification: data }));
      }
    },
    []
  );
};
