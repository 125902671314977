import { FC, useState, createContext, useEffect } from 'react';
import useVoxiberation from 'src/hooks/useVoxiberation';
import { TimeLeft } from 'src/types/timer';

interface TimerProgressContextValue {
  timeLeft: TimeLeft;
  progress: number;
  state: string;
  setTimeProgress: (timeLeft: TimeLeft, progress: number, state: string) => void;
}

const initialState = {
  timeLeft: null,
  progress: 100,
  state: 'in-progress'
};

export const TimerProgressContext = createContext<TimerProgressContextValue>({
  ...initialState,
  setTimeProgress: () => {}
});

export const TimerProgressProvider: FC = ({ children }) => {
  const { ask, voxiberationState, setVoxiberationState } = useVoxiberation();
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(initialState.timeLeft);
  const [progress, setProgress] = useState<number>(initialState.progress);
  const [state, setState] = useState<string>(ask?.phase_status || initialState.state);

  const setTimeProgress = (timeLeft: TimeLeft, progress: number, state: string) => {
    setTimeLeft(timeLeft);
    setProgress(progress);
    setState(state);
  };

  useEffect(() => {
    if (voxiberationState !== state) {
      setVoxiberationState(state);
    }
  }, [state]);

  return <TimerProgressContext.Provider value={{ timeLeft, progress, state, setTimeProgress }}>{children}</TimerProgressContext.Provider>;
};
