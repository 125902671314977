import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const BadgeIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 18 20" fill="none" style={{ color: 'transparent' }}>
      <path
        d="M14.4842 1.78353L9.26334 0.0427007C9.0924 -0.0142336 8.90761 -0.0142336 8.73667 0.0427007L3.51584 1.78353C2.68576 2.05927 1.96369 2.58958 1.45221 3.29913C0.940733 4.00867 0.66587 4.86136 0.666674 5.73603V10.0002C0.666674 16.3027 8.33334 19.7835 8.66167 19.9285C8.76819 19.9759 8.88345 20.0003 9.00001 20.0003C9.11656 20.0003 9.23182 19.9759 9.33834 19.9285C9.66667 19.7835 17.3333 16.3027 17.3333 10.0002V5.73603C17.3341 4.86136 17.0593 4.00867 16.5478 3.29913C16.0363 2.58958 15.3143 2.05927 14.4842 1.78353ZM12.9317 8.0977L9.37167 11.6577C9.22599 11.8044 9.05263 11.9206 8.86165 11.9997C8.67067 12.0788 8.46589 12.1192 8.25917 12.1185H8.23167C8.02075 12.1153 7.81267 12.0693 7.62002 11.9834C7.42737 11.8974 7.25415 11.7733 7.11084 11.6185L5.18917 9.61854C5.10595 9.54132 5.03938 9.44792 4.99355 9.34405C4.94772 9.24019 4.9236 9.12805 4.92266 9.01453C4.92172 8.90101 4.94398 8.78849 4.98809 8.68388C5.0322 8.57927 5.09721 8.48478 5.17915 8.4062C5.26108 8.32762 5.35821 8.26661 5.46457 8.22691C5.57093 8.18721 5.68428 8.16967 5.79766 8.17535C5.91105 8.18103 6.02208 8.20982 6.12393 8.25995C6.22579 8.31008 6.31634 8.38049 6.39001 8.46687L8.26001 10.4169L11.75 6.91687C11.9072 6.76507 12.1177 6.68107 12.3362 6.68297C12.5547 6.68487 12.7637 6.77251 12.9182 6.92702C13.0727 7.08153 13.1603 7.29054 13.1622 7.50903C13.1641 7.72753 13.0801 7.93803 12.9283 8.0952L12.9317 8.0977Z"
        fill={props.color || '#000000'}
      />
    </SvgIcon>
  );
};

export default BadgeIcon;
