import { ComponentType, Component as ReactComponent, PropsWithChildren } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Sidebar from 'src/components/Sidebar';
import Header from 'src/components/Header';
import { SidebarMenuProvider } from 'src/contexts/SidebarMenuContext';
import useSidebarMenu from 'src/hooks/useSidebarMenu';

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: 120px;
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.spacing(10)};
        margin-bottom: ${theme.spacing(7)};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
        // @media (max-width: ${theme.breakpoints.values.lg}px) {
        //     margin-top: ${theme.spacing(12)};
        // }
`
);

const DashboardLayout = () => {
  return (
    <>
      <Sidebar />
      <MainWrapper>
        <Header />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

const withMenuItems = <P extends object>(Component: ComponentType<P>) =>
  class WithMenuItems extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <SidebarMenuProvider heading="Dashboard">
          <Component {...(props as P)} />
        </SidebarMenuProvider>
      );
    }
  };

export default withMenuItems(DashboardLayout);
