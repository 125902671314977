import { askApi } from 'src/api';
import type { Ask, PaginatedData, PaginatedQuery } from 'src/types';
import { wrapCreateAsyncThunk as createAsyncThunk } from './util';

export const createAsk = createAsyncThunk<Ask, Ask>('ask/create', async (payload) => askApi.create(payload));

export const fetchAsksByCommunityId = createAsyncThunk<PaginatedData<Ask>, PaginatedQuery & { community_id: number }>(
  'ask/fetch',
  async ({ community_id, ...payload }) => askApi.fetchByCommunityId(community_id, payload)
);

export const getAskById = createAsyncThunk<Ask, number>('ask/get', async (ask_id) => askApi.get(ask_id));

export const updateAsk = createAsyncThunk<Ask, { ask_id: number; data: Partial<Ask> }>('ask/update', async (payload) =>
  askApi.update(payload.ask_id, payload.data)
);

export const updateAskState = createAsyncThunk<Ask, { ask_id: number; data: Ask }>(
  'ask/update',
  (payload) => new Promise((resolve) => resolve(payload.data))
);
