import stripeJs from '@stripe/stripe-js';

export interface StripeInfos {
  id?: number;
  subscription_id: number;
  stripe_customer_id: string;
  stripe_susbscription_id: string;
  stripe_card_id: string;
  stripe_name_on_card: string;
  stripe_last4_card: string;
  stripe_exp_card: string;
  stripe_metadata?: string;
}

export interface Subscription {
  id?: number;
  user_id: number;
  plan: 'trial' | 'jury' | 'assembly' | 'huddle' | 'payAsYouGo';
  payment_method: 'stripe' | 'crypto';
  is_active: boolean;
  created_at: number;
  voxiberation_count: number;
  community_limit: number;
  session_completed?: boolean;
}

export interface SubscribeInput {
  plan: string;
  payment_method: string;
  token?: stripeJs.Token;
  nameOnCard?: string;
  couponCode?: string;
}

export interface StripeSession {
  url: string;
  message?: string;
}

export interface PaymentResult {
  status: number;
  message?: string;
}

export enum SubscriptionPlan {
  TRIAL = 'trial',
  PAY_AS_YOU_GO = 'payAsYouGo',
  JURY = 'jury',
  ASSEMBLY = 'assembly',
  HUDDLE = 'huddle'
}

export enum SubscriptionCommunityLimit {
  huddle = 1,
  jury = 4,
  assembly = 10
}
