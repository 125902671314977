import React, { FC, PropsWithChildren, createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { discussionApi } from 'src/api';
import DiscussionWidget from 'src/components/Discussion/DiscussionWidget';
import { Community, Member, UserRole } from 'src/types';

interface DiscussionContextValue {
  showDiscussion: (status: boolean) => void;
  openDiscussion: (status: boolean) => void;
  update: boolean;
  toggleUpdate: () => void;
  memberDiscussion: Member[];
  community: Community;
  setCommunity: React.Dispatch<React.SetStateAction<Community>>;
  setMemberDiscussion: React.Dispatch<React.SetStateAction<Member[]>>;
  userRole: UserRole;
  setUserRole: React.Dispatch<React.SetStateAction<UserRole>>;
  ask_id?: number;
}

const initialState = {
  showDiscussion: null,
  openDiscussion: null,
  update: false,
  toggleUpdate: null,
  setMemberDiscussion: null,
  memberDiscussion: null,
  community: null,
  setCommunity: null,
  userRole: null,
  setUserRole: null,
  ask_id: null
};

export const DiscussionContext = createContext<DiscussionContextValue>({
  ...initialState
});

interface DiscussionContextProps {}

export const DiscussionProvider: FC<PropsWithChildren<DiscussionContextProps>> = ({ children }) => {
  const location = useLocation();

  const [community, setCommunity] = useState<Community>(null);
  const [memberDiscussion, setMemberDiscussion] = useState<Member[]>([]);

  const [open, setOpen] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [update, setUpdate] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<UserRole>('facilitator');

  const [ask_id, setAskId] = useState<number>();

  useEffect(() => {
    (async () => {
      if (community?.id) {
        await discussionApi.setUserStatus(community?.id, show && open);
      }
    })();
  }, [show, open, community?.id]);

  const toggleUpdate = () => {
    setUpdate((value) => !value);
  };

  const showDiscussion = (status: boolean) => {
    setShow(status);
  };

  const openDiscussion = (status: boolean) => {
    setOpen(status);
  };

  useEffect(() => {
    const regex = /\/ask\/(\d+)\//;
    const match = location.pathname.match(regex);
    if (match?.length > 1) {
      setAskId(+match[1]);
    } else {
      setAskId(null);
    }
  }, [location.pathname]);

  useEffect(() => {
    (async () => {
      if (community) {
        const { id } = community;
        const res = await discussionApi.getUserDiscussion(`${id}`, userRole, ask_id);
        const voxiDiscussionIndex = res.findIndex((member) => member.id === -1);
        if (voxiDiscussionIndex !== -1) {
          if (ask_id) {
            const [voxi] = res.splice(voxiDiscussionIndex, 1);
            res.unshift(voxi);
          } else {
            res.splice(voxiDiscussionIndex, 1);
          }
        }
        setMemberDiscussion(res);
      }
    })();
  }, [userRole, update, community?.id, ask_id]);

  return (
    <DiscussionContext.Provider
      value={{
        showDiscussion,
        openDiscussion,
        update,
        toggleUpdate,
        memberDiscussion,
        community,
        setCommunity,
        userRole,
        setUserRole,
        setMemberDiscussion,
        ask_id
      }}
    >
      <>
        {children}
        {/* 
          // !TODO removed temporary
          {community !== null && show && <DiscussionWidget open={open} setOpen={setOpen} />} 
        */}
      </>
    </DiscussionContext.Provider>
  );
};
