import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AnalyticsIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 -15 31 44" style={{ color: 'transparent' }}>
      <rect x="0.55" y="11.2165" width="5.29993" height="9.56656" rx="1.45" stroke={props.color} strokeWidth="1.1" />
      <rect x="9.0832" y="4.8166" width="5.29993" height="15.9665" rx="1.45" stroke={props.color} strokeWidth="1.1" />
      <rect x="26.1496" y="0.55" width="5.29993" height="20.2331" rx="1.45" stroke={props.color} strokeWidth="1.1" />
      <rect x="17.6164" y="6.9499" width="5.29993" height="13.8332" rx="1.45" stroke={props.color} strokeWidth="1.1" />
    </SvgIcon>
  );
};

export default AnalyticsIcon;
