import { FC, PropsWithChildren, useState, createContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { getMembershipInCommunity } from 'src/state/actions';
import { useAskCommunityId } from 'src/state/hooks';
import { useDispatch } from 'src/state/store';
import { Membership, Role, RoleUIAuthorization } from 'src/types';

const Roles: Role = {
  'no-access': {
    role: () => undefined,
    isMember: () => false,
    canManageCommunityMember: () => false,
    canAddCommunity: () => false,
    canManageAsk: () => false,
    canManageSubscription: () => false
  },
  facilitator: {
    role: () => 'facilitator',
    isMember: () => true,
    canManageCommunityMember: () => true,
    canAddCommunity: () => true,
    canManageAsk: () => true,
    canManageSubscription: () => true
  },
  member: {
    role: () => 'member',
    isMember: () => true,
    canManageCommunityMember: () => false,
    canAddCommunity: () => false,
    canManageAsk: () => false,
    canManageSubscription: () => false
  }
};

interface AuthorizationContextValue {
  authorization: RoleUIAuthorization;
  community_id?: number;
}

const initialState: AuthorizationContextValue = {
  authorization: null,
  community_id: null
};

export const AuthorizationContext = createContext<AuthorizationContextValue>({
  ...initialState
});

interface AuthorizationContextProps {}

export const AuthorizationProvider: FC<PropsWithChildren<AuthorizationContextProps>> = ({ children }) => {
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { community_id } = useParams();

  const askCommunityId = useAskCommunityId();
  const [authorization, setAuthorization] = useState<RoleUIAuthorization>(initialState.authorization);
  const [targetCommunityId, setTargetCommunityId] = useState<number | string>(initialState.community_id);

  useEffect(() => {
    let update = false;
    if (community_id && targetCommunityId !== parseInt(community_id, 10)) {
      update = true;
    }
    if (askCommunityId && targetCommunityId !== askCommunityId) {
      update = true;
    }
    if (update) setTargetCommunityId(community_id || askCommunityId);
  }, [askCommunityId, community_id]);

  useEffect(() => {
    if (!targetCommunityId || !user) {
      return;
    }
    dispatch(getMembershipInCommunity(targetCommunityId as number))
      .unwrap()
      .then((membership: Membership) => {
        const authorization = Roles[membership.role || 'no-access'];
        setAuthorization(authorization);
      });
  }, [targetCommunityId, user]);

  return (
    <AuthorizationContext.Provider value={{ authorization, community_id: targetCommunityId as number }}>{children}</AuthorizationContext.Provider>
  );
};
