import { combineReducers } from '@reduxjs/toolkit';
import { reducer as communitiesReducer } from 'src/state/slices/communities';
import { reducer as asksReducer } from 'src/state/slices/asks';
import { reducer as neoReducer } from 'src/state/slices/neo';
import { reducer as notificationReducer } from 'src/state/slices/notification';

const rootReducer = combineReducers({
  communities: communitiesReducer,
  asks: asksReducer,
  neo: neoReducer,
  notification: notificationReducer
});

export default rootReducer;
