import { FC, PropsWithChildren, useState, createContext, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { subscriptionApi } from 'src/api';
import useAuth from 'src/hooks/useAuth';
import useAuthorization from 'src/hooks/useAuthorization';
import { Subscription } from 'src/types/subscriptions';

interface SubscriptionContextValue {
  subscription: Subscription;
  onChecking: boolean;
  setSubscription: (sub: Subscription) => void;
  hasReachedVoxiberationLimit: boolean;
  fetchSubscription: () => void;
}

const initialState: SubscriptionContextValue = {
  subscription: null,
  onChecking: true,
  hasReachedVoxiberationLimit: false,
  setSubscription: () => {},
  fetchSubscription: () => {}
};

export const MAX_SUBSCRIPTION_PLAN = 'assembly';

export const SubscriptionContext = createContext<SubscriptionContextValue>({
  ...initialState
});

interface SubscriptionContextProps {}

export const SubscriptionProvider: FC<PropsWithChildren<SubscriptionContextProps>> = ({ children }) => {
  const [subscription, setSubscription] = useState<Subscription>(initialState.subscription);
  const [hasReachedVoxiberationLimit, setHasReachedVoxiberationLimit] = useState<boolean>(initialState.hasReachedVoxiberationLimit);
  const [onChecking, setOnChecking] = useState(initialState.onChecking);

  const location = useLocation();
  const { community_id } = useAuthorization();

  const { user, isAuthenticated } = useAuth();

  const fetchSubscription = useCallback(async () => {
    setOnChecking(true);

    try {
      try {
        const sub = await subscriptionApi.getUserSubscription(user.id);
        setSubscription(sub);

        const canDoVoxiberation = sub.voxiberation_count > 0;
        setHasReachedVoxiberationLimit(!canDoVoxiberation);
      } catch (err) {
        console.warn(err?.message);
      }
    } catch (err: any) {
      console.warn(err?.message);
    } finally {
      setOnChecking(false);
    }
  }, [user?.id, community_id, location.pathname]);

  useEffect(() => {
    if (user && isAuthenticated) fetchSubscription();
  }, [user, isAuthenticated, community_id]);

  return (
    <SubscriptionContext.Provider value={{ subscription, setSubscription, hasReachedVoxiberationLimit, onChecking, fetchSubscription }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
