import { Box, Hidden, SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

import useHeader from 'src/hooks/useHeader';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import React from 'react';

interface HeaderProps {
  sx?: SxProps;
}

const HeaderWrapper = styled(Box)(({ theme }) => ({
  // minHeight: theme.header.height,
  // color: theme.header.textColor,
  padding: theme.spacing(0, 0),
  right: 0,
  zIndex: 5,
  backgroundColor: theme.header.background,
  boxShadow: theme.header.boxShadow,
  position: 'fixed',
  justifyContent: 'space-between',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    left: '120px',
    width: 'auto'
  },

  [theme.breakpoints.down('lg')]: {
    backgroundColor: theme.colors.alpha.lavenderPale[100],
    borderRadius: theme.spacing(0, 0, 2, 2)
  }
}));

const AdditionalBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  display: 'none',
  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1),
    display: 'inherit'
  }
}));

const Header = ({ sx }: HeaderProps) => {
  const { currentHeader, additionalHeader, noHeader } = useHeader();
  if (noHeader) {
    return <></>;
  }

  const { setHeaderHeight } = useHeader();
  const headerRef = React.useRef<HTMLDivElement>();

  React.useEffect(() => {
    if (headerRef?.current) {
      const height = headerRef?.current?.offsetHeight;
      setHeaderHeight(height);
    }
    return () => {
      setHeaderHeight(null);
    };
  }, [headerRef?.current, currentHeader]);

  return (
    <HeaderWrapper sx={sx} ref={headerRef}>
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" width="80%" px={1}>
          {currentHeader}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end" width="20%">
          <HeaderButtons />
          <Hidden lgDown>
            <HeaderUserbox />
          </Hidden>
        </Box>
      </Box>
      {additionalHeader && (
        <AdditionalBox sx={{ padding: 1 }} textAlign="center">
          {additionalHeader}
        </AdditionalBox>
      )}
    </HeaderWrapper>
  );
};

export default Header;
