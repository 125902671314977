import { useSnackbar } from 'notistack';
import useSubscription from './useSubscription';
import { useNavigate } from 'react-router-dom';
import { subscriptionApi } from 'src/api';
import { useCallback, useState } from 'react';

export default () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { subscription } = useSubscription();
  const [loading, setLoading] = useState(false);

  const openBilling = useCallback(
    async (upgrade?: boolean) => {
      try {
        if (!subscription || !subscription?.plan) {
          navigate('/plan');
          return false;
        }
        setLoading(true);
        const checkoutUrl = await subscriptionApi.billing(upgrade);

        if (checkoutUrl) {
          window.open(checkoutUrl, '_blank');
        }
      } catch (err) {
        enqueueSnackbar(err?.message, { variant: 'error' });
      } finally {
        setLoading(false);
      }
    },
    [subscription]
  );

  return { loading, openBilling };
};
