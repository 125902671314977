import { communityApi } from 'src/api';
import type { Community, Membership, Member, Invitation, MagicLink } from 'src/types';
import { wrapCreateAsyncThunk as createAsyncThunk } from './util';

export const createCommunity = createAsyncThunk<Community, Community>('community/create', async (payload) => communityApi.create(payload));

export const updateCommunity = createAsyncThunk<Community, { id: Community['id']; payload: Partial<Community> }>(
  'community/update',
  async ({ id, payload }) => communityApi.update(id, payload)
);

export const verifyInvitation = createAsyncThunk<boolean, Community['id']>('community/invitation/verify', async (id) =>
  communityApi.verifyInvitation(id)
);

export const verifyMagicLink = createAsyncThunk<MagicLink, MagicLink['token']>('community/join', async (token) =>
  communityApi.verifyMagicLink(token)
);

export const acceptInvitation = createAsyncThunk<boolean, Community['id']>('community/invitation/accept', async (id) =>
  communityApi.acceptInvitation(id)
);

export const acceptViaMagicLink = createAsyncThunk<boolean, { community_id: Community['id']; token: MagicLink['token'] }>(
  'community/join',
  async ({ community_id, token }) => communityApi.join(community_id, token)
);

export const fetchCommunities = createAsyncThunk<Community[], null>('community/fetch', async () => communityApi.fetch());

export const getCommunityById = createAsyncThunk<Community, Community['id']>('community/get', async (id) => communityApi.getCommunityById(id));

export const fetchCommunityMembers = createAsyncThunk<Member[], number>('community/members/fetch', async (community_id: number) =>
  communityApi.members(community_id)
);

export const inviteMembers = createAsyncThunk<Membership[], { invitations: Invitation[]; community_id: number }>(
  'community/members/invite',
  async ({ invitations, community_id }: { invitations: Invitation[]; community_id: number }) => communityApi.invite(invitations, community_id)
);

export const getMembershipInCommunity = createAsyncThunk<Membership, number>('community/members/membership', async (community_id: number) =>
  communityApi.membership(community_id)
);

export const deleteMemberFromCommunity = createAsyncThunk<boolean, number>('community/members/delete', async (membershipId: number) =>
  communityApi.deleteMembership(membershipId)
);

export const deleteCommunityInvitation = createAsyncThunk<boolean, number>('community/invitation/delete', async (invitationId: number) =>
  communityApi.deleteInvitation(invitationId)
);

export const deleteCommunityFakeUser = createAsyncThunk<boolean, number>('community/fakeuser/delete', async (id: number) =>
  communityApi.deleteFakeUser(id)
);
