import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import groupBy from 'lodash.groupby';
import { RootState, useSelector, useDispatch } from '../store';
import { createAsk, fetchAsksByCommunityId, getAskById, updateAsk, updateAskState } from '../actions/asks';
import { Ask, PaginatedQuery } from 'src/types';
import useErrorHandler from 'src/hooks/useErrorHandler';

export const useAskAction = () => {
  const dispatch = useDispatch();

  return {
    /**
     * Create new Ask in a given community
     *
     * @param payload
     * @returns
     */
    createAsk: (payload: Ask) => dispatch(createAsk(payload)).unwrap(),
    /**
     * Update ask
     * @param ask_id
     * @param data
     * @returns
     */
    updateAsk: (ask_id: number, data: Partial<Ask>) => dispatch(updateAsk({ ask_id, data })).unwrap(),

    updateAskState: (ask_id: number, data: Ask) => dispatch(updateAskState({ ask_id, data })).unwrap()
  };
};

export const useAsks = (community_id: number, options?: PaginatedQuery) => {
  const dispatch = useDispatch();
  const handleError = useErrorHandler();

  useEffect(() => {
    if (community_id) {
      dispatch(fetchAsksByCommunityId({ ...options, community_id }))
        .unwrap()
        .catch((reason) => {
          handleError(new Error(reason.message));
        });
    }
  }, [community_id]);

  const asks = groupBy(
    useSelector((state: RootState) => state.asks.values),
    'community_id'
  );

  return asks[community_id] || [];
};

export const useStoreAsk = <T>(ask_id: T) => {
  return useSelector((state: RootState) => (state.asks.values || []).filter((a: Ask) => a.id === Number(ask_id)).pop());
};

export const useAskCommunityId = <T extends string | number = string>() => {
  const { ask_id, community_id } = useParams();
  const dispatch = useDispatch();
  const ask = useStoreAsk(ask_id);
  const [communityIdAsk, setCommunityIdAsk] = useState<T | undefined | string | number>(community_id || ask?.community_id);

  const fetchAsk = useCallback(async () => {
    if (!ask_id) {
      // Early exit if ask_id is not defined
      return;
    }

    if (ask?.community_id) {
      setCommunityIdAsk(ask?.community_id);
      return;
    }
    try {
      const fetchedAsk = await dispatch(getAskById(parseInt(ask_id, 10))).unwrap();
      setCommunityIdAsk(fetchedAsk?.community_id as T);
    } catch (error) {
      console.error('Failed to fetch ask:', error);
    }
  }, [ask_id, dispatch]);

  useEffect(() => {
    if (community_id) {
      // If community_id is already available in URL params, use it.
      return;
    }

    fetchAsk();
  }, [ask_id, fetchAsk]);

  return communityIdAsk as T;
};

export const useAsk = <T extends string | number>(ask_id: T) => {
  const dispatch = useDispatch();
  const ask = useStoreAsk(ask_id);

  useEffect(() => {
    if (!ask && ask_id) {
      console.log('useAsk', ask_id);
      dispatch(getAskById(ask_id as number));
    }
  }, [ask, ask_id]);

  return ask;
};
