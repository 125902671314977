import axios from 'src/utils/axios';
import { Community, Invitation, Membership, Member, MagicLink, WalletAddress } from 'src/types';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class CommunityApi extends ApiTokenSupport {
  /**
   * Create a new Community
   *
   * @param payload
   * @returns
   */
  async create(payload: Community): Promise<Community> {
    const resp = await axios.post<Community>('/api/community/create', payload, this.withAuthorization());

    return resp.data;
  }

  /**
   * Update a community by id
   *
   * @param payload
   * @returns
   */
  async update(id: Community['id'], payload: Partial<Community>): Promise<Community> {
    const resp = await axios.post<Community>('/api/community/update', { ...payload, id }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Verify if an invitation is valid, only authenticated user can perform
   *
   * @param community_id
   * @returns
   */
  async verifyInvitation(community_id: Community['id']): Promise<boolean> {
    const resp = await axios.post<boolean>('/api/invitation/verify', { community_id: community_id }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Accept invitation
   *
   * @param community_id
   * @returns
   */
  async acceptInvitation(community_id: Community['id']): Promise<boolean> {
    const resp = await axios.post<boolean>('/api/invitation/accept', { community_id: community_id }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Retrieve community members
   *
   * @param id
   * @returns
   */
  async members(id: number): Promise<Member[]> {
    const resp = await axios.get<Member[]>(`/api/community/${id}/members`, this.withAuthorization());

    return resp.data;
  }

  /**
   * Retrieve user's communities
   * @returns
   */
  async fetch(): Promise<Community[]> {
    const resp = await axios.get<Community[]>('/api/community/list/user', this.withAuthorization());

    return resp.data;
  }

  /**
   * Fetch a community
   * @returns
   */
  async getCommunityById(id: number): Promise<Community> {
    const resp = await axios.get<Community>(`/api/community/${id}`, this.withAuthorization());

    return resp.data;
  }

  /**
   * Invite member into communities
   *
   * @param invitations
   * @param community_id
   * @returns
   */
  async invite(invitations: Invitation[], community_id: number): Promise<Membership[]> {
    const resp = await axios.post('/api/community/invite', { invitations, community_id: community_id }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Retrieve user's membership for a community
   *
   * @param community_id
   * @returns
   */
  async membership(community_id: number): Promise<Membership> {
    const resp = await axios.get(`/api/community/${community_id}/membership`, this.withAuthorization());

    return resp.data;
  }

  /**
   * Delete user membership
   *
   * @param membershipId
   * @returns
   */
  async deleteMembership(membershipId: number): Promise<boolean> {
    const resp = await axios.delete(`/api/membership/${membershipId}`, this.withAuthorization());

    return resp.data;
  }

  /**
   * Delte an invitation
   *
   * @param invitationId
   * @returns
   */
  async deleteInvitation(invitationId: number): Promise<boolean> {
    const resp = await axios.delete(`/api/invitation/${invitationId}`, this.withAuthorization());

    return resp.data;
  }

  async deleteFakeUser(id: number): Promise<boolean> {
    const resp = await axios.delete(`/api/simulation/${id}`);

    return resp.data;
  }

  async generateFakeUser(community_id: number, count: number) {
    const resp = await axios.post(`/api/simulation/generate-users`, { community_id, count }, this.withAuthorization());

    return resp.data;
  }

  async delete(community_id: number): Promise<boolean> {
    const resp = await axios.delete<boolean>(`/api/community/${community_id}`, this.withAuthorization());
    return resp.data;
  }

  async updateInvitation(data: { id: number; name?: string; surname?: string }): Promise<void> {
    await axios.put(`/api/invitation/${data.id}`, data, this.withAuthorization());
  }

  /**
   * Verify magic link
   *
   * @param token
   * @returns
   */
  async verifyMagicLink(token: MagicLink['token']): Promise<MagicLink> {
    const resp = await axios.post<MagicLink>('/api/magic_link/verify', { token }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Create magic link
   *
   * @param community_id
   * @returns
   */
  async createMagicLink(community_id: MagicLink['community_id'], refresh?: boolean): Promise<MagicLink> {
    const resp = await axios.post<MagicLink>('/api/magic_link', { community_id, refresh }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Disable link
   *
   * @param id
   * @returns
   */
  async disableMagicLink(id: MagicLink['id']): Promise<MagicLink> {
    const resp = await axios.post<MagicLink>('/api/magic_link/update', { id, status: 'disabled' }, this.withAuthorization());

    return resp.data;
  }

  async join(community_id: Community['id'], token: MagicLink['token']): Promise<boolean> {
    const resp = await axios.post<boolean>('/api/community/join', { community_id: community_id, token }, this.withAuthorization());

    return resp.data;
  }

  async applyWallet(address: string, community_id: number, user_id: number): Promise<boolean> {
    const resp = await axios.post<boolean>('/api/wallet', { address, community_id, user_id }, this.withAuthorization());
    return resp.data;
  }

  async getWallet(community_id: number): Promise<WalletAddress> {
    const resp = await axios.post<WalletAddress>('/api/wallet/getWallet', { community_id }, this.withAuthorization());

    return resp.data;
  }
}

export default new CommunityApi(s.recursive('jwt'));
