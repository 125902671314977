import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';

const MainContent = styled(Box)(
  () => `
    height: 100%;
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`
);

const NoRightView: React.FC<{ title?: string }> = () => {
  return (
    <>
      <Helmet>
        <title>No right view</title>
      </Helmet>
      <MainContent>
        <Container maxWidth="md" sx={{ mt: 5 }}>
          <Box textAlign="center" mb={3}>
            <Container maxWidth="xs">
              <Typography variant="h1" sx={{ mt: 4, mb: 2 }}>
                Oops!
              </Typography>
              <Typography variant="h3" color="text.secondary" fontWeight="normal" sx={{ mb: 4 }}>
                403 - Unauthorized access
              </Typography>
            </Container>
          </Box>
        </Container>
      </MainContent>
    </>
  );
};

export default NoRightView;
