import { notificationApi } from 'src/api';
import type { Notification, PaginatedMetadata } from 'src/types';
import { wrapCreateAsyncThunk as createAsyncThunk } from './util';

export const fetchNotification = createAsyncThunk<Notification[], PaginatedMetadata>('notification/fetch', async (payload) =>
  notificationApi.fetch(payload)
);

export const createNotification = createAsyncThunk<Notification, Notification>('notification/create', async (payload) =>
  notificationApi.create(payload)
);

export const markNotificationAsRead = createAsyncThunk<number, Notification['id']>('notification/read', async (id: number) =>
  notificationApi.markNotificationAsRead(id)
);

export const markAllNotificationAsRead = createAsyncThunk<boolean, null>('notification/readAll', async () =>
  notificationApi.markAllNotificationAsRead()
);

export const readNotificationIcon = createAsyncThunk<number, null>('notification/readNotificationIcon', async () =>
  notificationApi.readNotificationIcon()
);
