import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const NotificationIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24" sx={{ fontSize: '1.8rem' }}>
      <path
        d="M15.9172 4.17172C14.7796 3.46283 13.4388 3.05371 12.0032 3.05371C7.88681 3.05371 4.5498 6.41742 4.5498 10.5668V19.4512H19.4566V10.5668C19.4566 10.2948 19.4423 10.0263 19.4144 9.76179"
        stroke="black"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M1.56689 19.4521H22.4365" stroke="black" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M9.76648 3.50591C9.91067 2.41021 10.8482 1.56445 11.9833 1.56445C13.1183 1.56445 14.0559 2.41021 14.2001 3.50591"
        stroke="black"
        strokeWidth="1.2"
      />
      <path
        d="M12.0021 22.4335C13.6487 22.4335 14.9835 21.0987 14.9835 19.4521H9.02075C9.02075 21.0987 10.3556 22.4335 12.0021 22.4335Z"
        stroke="black"
        strokeWidth="1.2"
      />
      <circle cx="17.9631" cy="6.78192" r="2.23603" fill="#6200EE" fillOpacity={1} />
    </SvgIcon>
  );
};

export default NotificationIcon;
