import { useRef, useState } from 'react';
import { alpha, Badge, Box, IconButton, List, Popover, Tooltip, Typography } from '@mui/material';
import ListTwoToneIcon from '@mui/icons-material/ListTwoTone';
import { styled } from '@mui/material/styles';
import NotificationIcon from 'src/components/Svg/NotificationIcon';
import { useNotificationActions, useNotificationState } from 'src/state/hooks';
import useMobile from 'src/hooks/useMobile';
import NotificationList from './NotificationList';
import { useNavigate } from 'react-router-dom';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${alpha(theme.palette.error.main, 0.1)};
        color: ${theme.palette.error.main};
        min-width: 16px; 
        height: 16px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
        width: ${theme.spacing(5)};
        height: ${theme.spacing(5)};
        color: ${theme.colors.alpha.black};
        &:hover {
          background-color: transparent;
        }
`
);

function HeaderNotifications() {
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const isMobile = useMobile();
  const { markAllNotificationAsRead, readNotificationIcon } = useNotificationActions();
  const { countUnread } = useNotificationState();
  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
  };

  const notificationBadgeHandler = () => {
    readNotificationIcon();

    if (isMobile) {
      navigate('/notifications');
      return;
    }

    setOpen(true);
  };

  return (
    <>
      <Tooltip arrow title="Notifications">
        <IconButtonWrapper
          // color="error"
          ref={ref}
          onClick={notificationBadgeHandler}
        >
          <NotificationsBadge
            badgeContent={countUnread}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              '.MuiBadge-badge': {
                backgroundColor: '#FF1943',
                color: 'white',
                fontSize: '14px',
                minWidth: '20px',
                height: '20px',
                padding: '5px',
                marginTop: '5px',
                marginRight: '10px'
              }
            }}
          >
            <NotificationIcon fill="none" fillOpacity={0} />
          </NotificationsBadge>
        </IconButtonWrapper>
      </Tooltip>
      {!isMobile && (
        <Popover
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box sx={{ maxWidth: '350px', minWidth: '300px' }}>
            <Box sx={{ p: 2 }} display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h3" sx={{ display: 'inline' }}>
                Notifications
              </Typography>
              <Box
                sx={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  float: 'right'
                }}
              >
                <Tooltip title="Mark all as read">
                  <IconButton
                    size="small"
                    onClick={() => {
                      markAllNotificationAsRead();
                    }}
                  >
                    <ListTwoToneIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <NotificationList containerStyle={{ maxHeight: '300px', overflow: 'auto' }} />
          </Box>
        </Popover>
      )}
    </>
  );
}

export default HeaderNotifications;
