import { createAsyncThunk } from '@reduxjs/toolkit';

export const wrapCreateAsyncThunk = <Result, Payload>(actionName: string, promiseFunc: (payload: Payload) => Promise<Result>) => {
  // @ts-ignore
  return createAsyncThunk<Result, Payload>(actionName, async (payload, { rejectWithValue }) => {
    try {
      return await promiseFunc(payload);
    } catch (e) {
      if (!e.response) {
        return Promise.reject(e);
      }

      return rejectWithValue(e.response);
    }
  });
};
