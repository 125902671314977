/* eslint-disable no-case-declarations */
import React, { FC, useState, createContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import findIndex from 'lodash.findindex';
import { useAsk } from 'src/state/hooks';
import { ActiveUser, Ask, CommentStream, User } from 'src/types';
import useAuth from 'src/hooks/useAuth';
import { askApi, commentApi, userApi } from 'src/api';
import { storage as s } from '../utils/storage';

export interface ChatStream {
  label: string;
  id: string;
}

export const FORUM = 'forum';
export const COMMENTING = 'commenting';
export const DELIBERATION_1 = 'deliberation phase 1';
export const DELIBERATION_2 = 'deliberation phase 2';
export const FINAL_RANKING = 'final ranking';
export const VOTE_PROPOSAL = 'vote on the proposal';

export const PHASES = [
  { key: 0, label: FORUM, id: 'forum-phase' },
  { key: 1, label: COMMENTING, id: 'commenting-phase' },
  { key: 2, label: DELIBERATION_1, id: 'deliberation-round-one-phase' },
  { key: 3, label: DELIBERATION_2, id: 'deliberation-round-two-phase' },
  { key: 4, label: FINAL_RANKING, id: 'comment-ranking-phase' },
  { key: 5, label: VOTE_PROPOSAL, id: 'voting-phase' }
];

export const PATCH_PHASES = {
  [FORUM]: 'Forum',
  [COMMENTING]: 'Commenting',
  [DELIBERATION_1]: 'Deliberation round 1',
  [DELIBERATION_2]: 'Deliberation round 2',
  [FINAL_RANKING]: 'Comment ranking',
  [VOTE_PROPOSAL]: 'Voting'
};

export const MAIN_HALL = 'Main Hall';
export const FACILITATOR = 'Facilitator';
export const MEMBER = 'Member';
export const VOXI = 'Voxi';

export const CHAT_STREAM: ChatStream[] = [
  {
    label: MAIN_HALL,
    id: 'main-hall-chat'
  },
  {
    label: FACILITATOR,
    id: 'facilitator-chat'
  },
  {
    label: VOXI,
    id: 'voxi-chat'
  }
];

export const CHAT_STREAM_FACILITATOR: ChatStream[] = [
  {
    label: MAIN_HALL,
    id: 'facilitator-main-hall-chat'
  },
  {
    label: MEMBER,
    id: 'member-chat'
  },
  {
    label: VOXI,
    id: 'voxi-chat'
  }
];

export const resolvePhase = (phase?: string) => {
  // put FORUM as default phase
  if (!phase) {
    return PHASES[0].key;
  }

  if (phase === 'done') {
    return 5;
  }
  const i = findIndex(PHASES, (p) => p.label === phase);
  if (i === -1) {
    return null;
  }
  return PHASES[i].key;
};

export interface MemberChat {
  id: number;
  user: User;
  unread: number;
  lastMessage: CommentStream;
}

interface VoxiberationState {
  commentStream: CommentStream[];
  initialLoading: boolean;
  ask: Ask;
  chatStream: string;
  unreadChatStream: {
    [MAIN_HALL]: number;
    [FACILITATOR]: number;
    [MEMBER]: number;
    [VOXI]: number;
  };
  memberChats: MemberChat[];
  activeMemberChat: number;
  voxiberationState: string;
  startTutorial: boolean;
  endPhase?: boolean;
}

interface VoxiberationStateRef {
  currentAsk: Ask;
  currentChatStream: string;
  countUnreadChatStream: any;
  isFacilitator: boolean;
  currentActiveMemberChat: number;
  currentMembersChat: MemberChat[];
}

interface VoxiberationContextValue extends VoxiberationState {
  refresh: () => void;
  fetchMemberStream: ({ memberId, unread }: { memberId: number; unread: number }) => void;
  fetchMembersChat: () => void;
  pushCommentInPhase: (comments: CommentStream[], reference?: VoxiberationStateRef) => void;
  pushAndPatchCommentInPhase: (comments: CommentStream[]) => void;
  sendCommment: (comment: string) => Promise<void>;
  sendFacilitatorCommment: (comment: string) => Promise<void>;
  sendUserDiscussionCommment: (comment: string) => Promise<void>;
  postVote: (vote: string) => Promise<void>;
  sendDeliberationChoice: (comment: CommentStream) => Promise<void>;
  updateActiveUser: (payload: Partial<ActiveUser>) => Promise<void>;
  setChatStream: (chatStream: string) => void;
  setVoxiberationState: (state: string) => void;
  setStartTutorial: (state: boolean) => void;
  setActiveMemberChat: React.Dispatch<React.SetStateAction<number>>;
  setEndPhase: React.Dispatch<React.SetStateAction<boolean>>;
}

const initialState: VoxiberationState = {
  ask: null,
  commentStream: [],
  initialLoading: false,
  chatStream: CHAT_STREAM[0].label,
  unreadChatStream: {
    [MAIN_HALL]: 0,
    [FACILITATOR]: 0,
    [MEMBER]: 0,
    [VOXI]: 0
  },
  memberChats: [],
  activeMemberChat: null,
  voxiberationState: '',
  startTutorial: false
};

type PushAction = {
  type: 'PUSH';
  payload: {
    comments?: CommentStream[];
  };
};

type InitAction = {
  type: 'PATCH';
  payload: {
    comments?: CommentStream[];
    chatStream?: string;
  };
};

const commentStreamHandlers = {
  PUSH: (state: CommentStream[], action: PushAction): CommentStream[] => {
    const { comments } = action.payload;

    let oldState = [...state];

    const filteredComments = comments.filter((comment: CommentStream) => {
      return (
        findIndex(oldState, (s: CommentStream) => {
          return s.id === comment.id && s.text === comment.text && new Date(s.created_at).getTime() === new Date(comment.created_at).getTime();
        }) === -1
      );
    });

    return [...state, ...filteredComments];
  },
  PATCH: (state: CommentStream[], action: InitAction): CommentStream[] => {
    const { comments } = action.payload;

    return comments || state;
  }
};

const commentStreamReducer = (state: CommentStream[], action: PushAction | InitAction): CommentStream[] =>
  // @ts-ignore
  commentStreamHandlers[action.type] ? commentStreamHandlers[action.type](state, action) : state;

export const VoxiberationContext = createContext<VoxiberationContextValue>({
  ...initialState,
  refresh: () => {},
  fetchMemberStream: () => {},
  fetchMembersChat: () => {},
  pushCommentInPhase: () => {},
  pushAndPatchCommentInPhase: () => {},
  sendCommment: () => Promise.resolve(),
  sendDeliberationChoice: () => Promise.resolve(),
  postVote: () => Promise.resolve(),
  updateActiveUser: () => Promise.resolve(),
  sendFacilitatorCommment: () => Promise.resolve(),
  sendUserDiscussionCommment: () => Promise.resolve(),
  setChatStream: () => {},
  setVoxiberationState: () => {},
  setStartTutorial: () => {},
  setActiveMemberChat: () => {},
  setEndPhase: () => {}
});

export const VoxiberationProvider: FC = ({ children }) => {
  const { ask_id } = useParams();
  const { user } = useAuth();
  const ask = useAsk(ask_id);

  const [commentStream, dispatch] = useReducer(commentStreamReducer, []);
  const [initialLoading, setInitLoading] = useState<boolean>(true);
  const [chatStream, setChatStream] = useState(initialState.chatStream);
  const [unreadChatStream, setUnreadChatStream] = useState(initialState.unreadChatStream);
  const [memberChats, setMemberChats] = useState(initialState.memberChats);
  const [activeMemberChat, setActiveMemberChat] = useState<number>(null);
  const [startTutorial, setStartTutorial] = useState<boolean>(initialState.startTutorial);
  const [endPhase, setEndPhase] = useState<boolean>(false);

  // Catch voxiberation state from event like terminate | in progress | paused | resume the voxiberation
  const [voxiberationState, setVoxiberationState] = useState(ask?.phase_status);

  const dispatchPushComments = (comments: CommentStream[]) => {
    dispatch({
      type: 'PUSH',
      payload: { comments: comments.map((comment) => ({ ...comment, ask_id })) }
    });
  };

  // Function to increment unread counts and increment detail of label (really used on MEMBER stream)
  const incrementUnreadCount = (phaseCountStream: any, chat_stream: string, newCount?: number) => {
    setUnreadChatStream({
      ...phaseCountStream,
      [chat_stream]: newCount !== undefined ? newCount : phaseCountStream[chat_stream] + 1
    });
  };

  const updateMemberChat = (memberId: number, allMembersChats: MemberChat[], fields: any) => {
    let updatedIndex = 0;
    let updatedMembersChats = allMembersChats.map((member, index) => {
      if (member.user.id === memberId) {
        updatedIndex = index;
        // put as default unread + 1, can be overridden on fields parameter
        return { ...member, unread: member.unread + 1, ...fields };
      }
      return member;
    });

    // if lastmessage updated put the chat on first place
    if (fields?.lastMessage) {
      const memberChat = updatedMembersChats[updatedIndex];
      updatedMembersChats.splice(updatedIndex, 1);
      updatedMembersChats = [memberChat, ...updatedMembersChats];
    }

    setMemberChats(updatedMembersChats);
  };

  // Function to handle facilitator-specific logic
  const handleFacilitatorComments = (
    comment: CommentStream,
    phaseChatStream: string,
    activeMember: number,
    phaseCountStream: any,
    allMembersChats: MemberChat[]
  ) => {
    // if a new member, directly add the comment user
    // as random id , use : memberChats.length
    if (
      comment.from !== 'facilitator' &&
      comment.chat_stream === FACILITATOR &&
      !allMembersChats.find((member) => member.user.id === comment.created_by.id)
    ) {
      setMemberChats([{ id: memberChats.length, user: comment.created_by as User, unread: 1, lastMessage: { ...comment } }, ...allMembersChats]);
      incrementUnreadCount(phaseCountStream, MEMBER);
      return true;
    }

    if (phaseChatStream === MEMBER && (comment.chat_stream === MEMBER || comment.chat_stream === FACILITATOR)) {
      if (comment.from === 'facilitator' || comment.created_by.id === activeMember) {
        dispatchPushComments([comment]);
        updateMemberChat(comment.from === 'facilitator' ? comment.to : comment.created_by.id, allMembersChats, { unread: 0, lastMessage: comment });
      } else {
        updateMemberChat(comment.created_by.id, allMembersChats, { lastMessage: comment });
      }
      return true;
    }

    // Increment the badge on select stream if the MEMBER stream on facilitator is not active
    if (comment.chat_stream === FACILITATOR && phaseChatStream !== MEMBER) {
      updateMemberChat(comment.created_by.id, allMembersChats, { lastMessage: comment });
      incrementUnreadCount(phaseCountStream, MEMBER);
      return true;
    }

    if (comment.chat_stream === MAIN_HALL && phaseChatStream !== MAIN_HALL) {
      incrementUnreadCount(phaseCountStream, MAIN_HALL);
      return true;
    }

    return false;
  };

  const fetchCurrentStream = async () => {
    // updated when chat stream changed
    const stream = await commentApi.fetchCommentStream(ask_id, chatStream);
    dispatch({ type: 'PATCH', payload: { comments: stream } });
  };

  // get all comments with a member
  const fetchMemberStream = async ({ memberId, unread }: { memberId: number; unread: number }) => {
    // set as active chat on member
    setActiveMemberChat(memberId);

    // get all comment stream with the member
    const stream = await commentApi.fetchMemberStream(ask_id, memberId);
    dispatch({ type: 'PATCH', payload: { comments: stream } });

    // reduce the MEMBER stream badge count
    const newCount = unreadChatStream[MEMBER] - unread;

    incrementUnreadCount(unreadChatStream, MEMBER, newCount < 0 ? 0 : newCount);

    // put the count unread to 0
    updateMemberChat(memberId, memberChats, { unread: 0 });
  };

  // get all recent comments with members
  const fetchMembersChat = async () => {
    const chats = await commentApi.fetchMembersChat(ask_id);
    setMemberChats(chats);
  };

  const pushCommentInPhase = (comments: CommentStream[], reference?: VoxiberationStateRef) => {
    const {
      currentChatStream = chatStream,
      countUnreadChatStream = unreadChatStream,
      currentAsk = ask,
      currentActiveMemberChat = activeMemberChat,
      currentMembersChat = memberChats
    } = reference || {};

    comments.forEach((comment) => {
      // check special facilitator
      if (
        reference?.isFacilitator &&
        handleFacilitatorComments(comment, currentChatStream, currentActiveMemberChat, countUnreadChatStream, currentMembersChat)
      ) {
        return;
      }

      if (comment.chat_stream === currentChatStream) {
        dispatchPushComments([comment]);
      } else {
        incrementUnreadCount(countUnreadChatStream, comment.chat_stream);
      }
    });
  };

  const pushAndPatchCommentInPhase = (comments: CommentStream[]) => {
    dispatch({ type: 'PATCH', payload: { comments: comments.map((comment) => ({ ...comment, ask_id: ask_id })) } });
  };

  // post member comment
  const sendCommment = async (comment: string) => {
    const userComment = await commentApi.postComment({ ask_id: ask_id, member_id: user.id, text: comment });
    dispatch({ type: 'PUSH', payload: { comments: [{ ...userComment, type: 'text' }] } });
  };

  const sendFacilitatorCommment = async (comment: string) => {
    await commentApi.postCommentStream({
      ask_id: +ask_id,
      type: 'text',
      from: 'facilitator',
      to: chatStream === MEMBER ? activeMemberChat : null,
      text: comment,
      unpublish: false,
      created_by: user.id,
      chat_stream: chatStream === MEMBER ? FACILITATOR : chatStream,
      phase: ask.phase
    });
  };

  const sendUserDiscussionCommment = async (comment: string) => {
    await commentApi.postCommentStream({
      ask_id: +ask_id,
      type: 'text',
      from: 'user',
      to: chatStream === FACILITATOR ? ask.creator : null,
      text: comment,
      unpublish: false,
      created_by: user.id,
      chat_stream: chatStream,
      phase: ask.phase
    });
  };

  // post member deliberation choice
  const sendDeliberationChoice = async (comment: CommentStream) => {
    const {
      id,
      theme_id,
      pair: { top, bottom }
    } = comment;
    await commentApi.postDeliberationChoice(ask.id, ask.phase === 'deliberation phase 2' ? 2 : 1, user.id, theme_id, id, top, bottom);
    dispatch({
      type: 'PUSH',
      payload: {
        comments: [
          {
            ...comment,
            member_id: user.id,
            chat_stream: MAIN_HALL
          }
        ]
      }
    });
  };

  const postVote = async (vote: string) => {
    await askApi.voteOntheProposal(ask.id, user.id, vote);
    dispatch({ type: 'PUSH', payload: { comments: [{ id: -2, ask_id: ask.id, member_id: user.id, text: vote, type: 'text' }] } });
    s.remove(`ask_${ask_id}`);
  };

  // initiate the current member comment stream
  // handle chant stream change
  useEffect(() => {
    // put empty the chat content to have a fresh chat on change stream
    pushAndPatchCommentInPhase([]);

    // if MEMBER stream do nothing as the fetch is made on select the member
    if (chatStream === MEMBER) {
      return;
    }

    setActiveMemberChat(null);
    setUnreadChatStream({
      ...unreadChatStream,
      [chatStream]: 0
    });
    const fetch = async () => {
      await fetchCurrentStream();
      setInitLoading(false);
    };
    fetch();
  }, [chatStream]);

  const updateActiveUser = async (payload: Partial<ActiveUser>) => {
    await userApi.updateActiveUser({ user_id: user.id, ask_id: +ask_id, ...payload });
  };

  // refresh comment stream on each phase change
  useEffect(() => {
    const refresh = async () => {
      await fetchCurrentStream();
    };
    if (!initialLoading && ask?.phase === 'done') refresh();
  }, [ask?.phase]);

  // reset the voxiberation state whenever ask phase status changes
  useEffect(() => {
    if (ask?.phase_status) {
      setVoxiberationState(ask?.phase_status);
    }
  }, [ask?.phase_status]);

  useEffect(() => {
    if (startTutorial) setChatStream(null);
    else setChatStream(MAIN_HALL);
  }, [startTutorial]);

  return (
    <VoxiberationContext.Provider
      value={{
        ask,
        activeMemberChat,
        chatStream,
        commentStream,
        initialLoading,
        memberChats,
        unreadChatStream,
        voxiberationState,
        startTutorial,
        pushCommentInPhase,
        pushAndPatchCommentInPhase,
        sendFacilitatorCommment,
        sendCommment,
        refresh: fetchCurrentStream,
        fetchMemberStream,
        fetchMembersChat,
        sendDeliberationChoice,
        postVote,
        updateActiveUser,
        sendUserDiscussionCommment,
        setChatStream,
        setVoxiberationState,
        setStartTutorial,
        setActiveMemberChat,
        endPhase,
        setEndPhase
      }}
    >
      {children}
    </VoxiberationContext.Provider>
  );
};
