import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';
import { Transaction } from 'src/types/transaction';

class TransactionApi extends ApiTokenSupport {
  async filterTransaction(filterParams: any, filterBy?: string): Promise<{ totalRows: number; rows: Transaction[] }> {
    const resp = await axios.post<{ totalRows: number; rows: Transaction[] }>(
      `/api/transactions/filter`,
      { paginate: filterParams, filterBy },
      this.withAuthorization()
    );

    return resp.data;
  }

  async totalRows(user_id: number): Promise<number> {
    const resp = await axios.post<number>(`/api/transactions/count`, { query: { user_id } }, this.withAuthorization());

    return resp.data;
  }
}

export default new TransactionApi(s.recursive('jwt'));
