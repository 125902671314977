import React, { FC, PropsWithChildren, useState, createContext, ReactNode, useRef } from 'react';

interface HeaderContextValue {
  currentHeader: ReactNode;
  setCurrentHeader: React.Dispatch<React.SetStateAction<ReactNode>>;
  additionalHeader?: ReactNode;
  setAdditionalHeader?: React.Dispatch<React.SetStateAction<ReactNode>>;
  noHeader?: boolean;
  setNoHeader?: React.Dispatch<React.SetStateAction<boolean>>;
  headerHeight?: number;
  setHeaderHeight?: React.Dispatch<React.SetStateAction<number>>;
}

const initialState = {
  currentHeader: null
};

export const HeaderContext = createContext<HeaderContextValue>({
  ...initialState,
  setCurrentHeader: () => {}
});

interface HeaderContextProps {}

export const HeaderProvider: FC<PropsWithChildren<HeaderContextProps>> = ({ children }) => {
  const [currentHeader, setCurrentHeader] = useState<ReactNode>(initialState.currentHeader);
  const [additionalHeader, setAdditionalHeader] = useState<ReactNode>();
  const [noHeader, setNoHeader] = useState<boolean>(false);
  const [headerHeight, setHeaderHeight] = useState<number>();
  return (
    <HeaderContext.Provider
      value={{ currentHeader, setCurrentHeader, additionalHeader, setAdditionalHeader, noHeader, setNoHeader, headerHeight, setHeaderHeight }}
    >
      {children}
    </HeaderContext.Provider>
  );
};
