/* eslint-disable @typescript-eslint/no-invalid-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable func-names */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import * as LZString from 'lz-string';

const compression = {
  lz() {
    if (!LZString) {
      return undefined;
    }

    function LzCompressor() {}

    // http://pieroxy.net/blog/pages/lz-string/guide.html
    LzCompressor.prototype = {
      compress(str) {
        return LZString.compress(str);
      },

      decompress(str) {
        return LZString.decompress(str);
      }
    };

    return new LzCompressor();
  }
};

/**
 * Adapter for localStorage
 *
 * @param key
 * @param options
 *
 * @constructor
 */
function DataStorage(key, options) {
  options = options || {};
  this.data = {};
  this.key = key || '__storage';
  this.options = this.merge(
    {
      compression: false,
      split: '/',
      expireOn: false
    },
    options
  );
  this.pull();
}

DataStorage.prototype = {
  askCompression(withCompression, withoutCompression, value) {
    const c = this.options.compression ? compression[this.options.compression] : undefined;

    if (typeof c === 'function') {
      value = [value];
      value.unshift(c());
      withCompression.apply(this, value);
    } else {
      withoutCompression.apply(this, [value]);
    }
  },

  merge(a, b) {
    return { ...a, ...b };
  },

  initWith(data) {
    this.flush();
    this.data = data;

    return this.push();
  },

  /**
   * Grab data from native localStorage
   */
  pull() {
    let stored = null;

    this.askCompression(
      function (zip) {
        stored = zip.decompress(localStorage.getItem(this.key));
      },
      function () {
        stored = localStorage.getItem(this.key);
      },
      stored
    );

    if (stored != null) {
      try {
        this.data = JSON.parse(stored);
      } catch (e) {
        console.warn('could not fetch cache data', e.message);
      }
    }

    return this;
  },

  /**
   * Push all data package in localStorage engine
   */
  push() {
    const str = JSON.stringify(this.data);

    this.askCompression(
      function (zip, value) {
        localStorage.setItem(this.key, zip.compress(value));
      },
      function (value) {
        localStorage.setItem(this.key, value);
      },
      str
    );

    return this;
  },

  /**
   * Set a pair key-value data to data storage
   *
   * @param key
   * @param data
   */
  set(key, data) {
    this.data[key] = data;

    return this.push();
  },

  /**
   * Build a deep json object
   *
   * @param path
   * @param value
   * @returns {*}
   */
  buildTree(path, value) {
    const spl = new RegExp(this.options.split, 'ig');
    path = path.split(spl);

    let l = '';
    let r = '}';

    path.forEach((key, offset) => {
      l += `{"${key}":`;
      if (offset < path.length - 1) {
        r = `}${r}`;
      } else if (typeof value === 'object') {
        r = JSON.stringify(value) + r;
      } else if (typeof value === 'string') {
        // ATTENTION: addressing https://wausolutions.atlassian.net/browse/VOXIBERATE-209
        // we would need to escape " and \ chars, even others
        // see https://stackoverflow.com/questions/19176024/how-to-escape-special-characters-in-building-a-json-string
        // instead of using the value directly we ensure to use the stringified value
        r = `${JSON.stringify(value)}${r}`;
      } else {
        r = value.toString() + r;
      }
    });

    l += r;

    // quick fix of JSON broken by newlines
    l = l.replace(/\r?\n|\r/g, '');

    return JSON.parse(l);
  },

  /**
   * Store value targeted by a deep key
   *
   * @param key
   * @param value
   * @returns {*}
   */
  record(key, value) {
    this.pull();

    this.data = {
      ...this.data,
      ...this.buildTree(key, value)
    };

    return this.push();
  },

  __record(key, value) {
    const spl = new RegExp(this.options.split, 'ig');
    const path = key.split(spl);

    const clone = function (arg) {
      return { ...arg };
    };

    this.pull();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    let recursiveValue = {};
    const data = clone(this.data);
    let val;

    path.forEach((name, offset) => {
      if (offset === 0) {
        val = that.__get(data, name, {});
      } else {
        val = that.__get(recursiveValue, name, {});
      }

      if (offset < path.length - 1) {
        // not yet end
        if (typeof val !== 'object') {
          // if not object, force as object, !Notice : old value will be overwritten
          val = {};
        }

        that.__set(recursiveValue, name, val);
      } else {
        that.__set(data, name, value);
      }

      recursiveValue = recursiveValue[name];
    });

    this.data = data;

    return this.push();
  },

  /**
   * Internal function to set data by key
   *
   * @param data
   * @param key
   * @param value
   * @returns {*}
   * @private
   */
  __set(data, key, value) {
    data[key] = value;

    return data;
  },

  /**
   * Internal method to fetch data from its key
   *
   * @param data
   * @param key
   * @param defaultValue
   * @returns {*}
   * @private
   */
  __get(data, key, defaultValue) {
    return typeof data[key] === 'undefined' ? defaultValue : data[key];
  },

  /**
   * Get data by its key, return a default value if not set
   *
   * @param key
   * @param defaultValue
   */
  get(key, defaultValue) {
    this.pull();
    return typeof this.data[key] === 'undefined' ? defaultValue : this.data[key];
  },

  /**
   * Get data recursively by key, use split option
   *
   * @param key
   * @param defaultValue
   * @returns {*}
   */
  recursive(key, defaultValue) {
    const spl = new RegExp(this.options.split, 'ig');
    const path = key.split(spl);

    this.pull();
    let { data } = this;
    let result = defaultValue;

    path.forEach((k) => {
      if (typeof data[k] !== 'undefined') {
        data = data[k];
        result = data;
      } else result = defaultValue;
    });

    return result;
  },

  /**
   * Remove set of data
   *
   * @param key
   * @returns {*}
   */
  remove(key) {
    const spl = new RegExp(this.options.split, 'ig');
    const path = key.split(spl);

    if (path.length > 1) {
      const kdel = path.reverse().shift();
      key = path.reverse().join(this.options.split);

      const temp = this.recursive(key);
      if (typeof temp[kdel] !== 'undefined') {
        delete temp[kdel];
      }
    } else if (typeof this.data[key] !== 'undefined') {
      delete this.data[key];
    }

    return this.push();
  },

  /**
   * Reset all data
   */
  flush() {
    this.askCompression(
      function (zip, value) {
        localStorage.setItem(this.key, zip.compress(value));
      },
      function (value) {
        localStorage.setItem(this.key, value);
      },
      JSON.stringify({})
    );

    this.data = {};

    return this;
  },

  /**
   * Retrieve all stored data
   *
   * @returns {{}|*}
   */
  fetchAll() {
    this.pull();

    return this.data;
  }
};

export default DataStorage;

const args = { split: '/' };
if (process.env.NODE_ENV === 'production') {
  args.compression = 'lz';
}

export const storage = new DataStorage('_vox', args);
