import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const CommunityIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 -12 41 44" style={{ color: 'transparent' }}>
      <ellipse cx="21.9917" cy="18.4684" rx="8.25156" ry="8.25156" stroke={props.color} strokeWidth="1.2" />
      <ellipse cx="21.9908" cy="17.5513" rx="2.75052" ry="2.75052" stroke={props.color} strokeWidth="1.2" />
      <path
        d="M16.5881 24.77C17.0716 22.2258 19.3073 20.3022 21.9922 20.3022C24.6772 20.3022 26.9128 22.2258 27.3964 24.77"
        stroke={props.color}
        strokeWidth="1.2"
      />
      <ellipse cx="33.5712" cy="6.42857" rx="5.42857" ry="5.42857" stroke={props.color} strokeWidth="1.1" />
      <ellipse cx="33.5712" cy="5.82515" rx="1.80952" ry="1.80952" stroke={props.color} strokeWidth="1.1" />
      <path
        d="M30.0156 10.5745C30.3337 8.90072 31.8045 7.63525 33.5709 7.63525C35.3373 7.63525 36.8081 8.90072 37.1262 10.5745"
        stroke={props.color}
        strokeWidth="1.1"
      />
      <ellipse cx="6.42857" cy="11.8573" rx="5.42857" ry="5.42857" stroke={props.color} strokeWidth="1.1" />
      <ellipse cx="6.42867" cy="11.2534" rx="1.80952" ry="1.80952" stroke={props.color} strokeWidth="1.1" />
      <path
        d="M2.87305 16.0032C3.19115 14.3294 4.66197 13.064 6.42835 13.064C8.19473 13.064 9.66555 14.3294 9.98365 16.0032"
        stroke={props.color}
        strokeWidth="1.1"
      />
      <path d="M28.1426 12.9429L30.314 10.7715" stroke={props.color} strokeWidth="1.2" />
      <path d="M15.1134 15.114L11.8561 14.0283" stroke={props.color} strokeWidth="1.2" />
    </SvgIcon>
  );
};

export default CommunityIcon;
