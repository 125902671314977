import { Notification, PaginatedMetadata } from 'src/types';
import { RootState, useDispatch, useSelector } from '../store';
import {
  createNotification,
  fetchNotification,
  markNotificationAsRead,
  markAllNotificationAsRead,
  readNotificationIcon
} from '../actions/notification';
import omit from 'lodash.omit';

export const useNotificationActions = () => {
  const dispatch = useDispatch();

  return {
    /**
     * Create a new notification
     *
     * @param payload
     * @returns
     */
    createNotification: (payload: Notification) => dispatch(createNotification(payload)).unwrap(),

    /**
     * Fetch notifications for the connected user
     * @returns
     */
    fetchNotification: (payload?: PaginatedMetadata) => dispatch(fetchNotification(payload)).unwrap(),
    /**
     * Update a notification by its id, this will dispatch `markNotificationAsRead` action
     *
     * @returns
     */
    markNotificationAsRead: (id: Notification['id']) => dispatch(markNotificationAsRead(id)).unwrap(),
    /**
     * mark all notifications as read
     *
     * @returns
     */
    markAllNotificationAsRead: () => dispatch(markAllNotificationAsRead()).unwrap(),
    /**
     * restore notification icon
     *
     * @returns
     */
    readNotificationIcon: () => dispatch(readNotificationIcon()).unwrap()
  };
};

export const useNotification = () => {
  return useSelector((state: RootState) => state.notification.notifications);
};

export const useNotificationState = () => {
  return useSelector((state: RootState) => omit(state.notification, 'notifications'));
};
