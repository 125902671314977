import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const VoxIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 41 44" style={{ color: 'transparent' }}>
      <circle cx="20.1953" cy="22.0234" r="13.7109" stroke={props.color} strokeWidth="1.25" />
      <circle cx="20.1953" cy="7.39844" r="6.39844" stroke={props.color} strokeWidth="1.25" />
      <circle cx="20.1953" cy="36.6484" r="6.39844" stroke={props.color} strokeWidth="1.25" />
      <circle cx="33.6016" cy="28.7266" r="6.39844" stroke={props.color} strokeWidth="1.25" />
      <circle cx="7.39844" cy="14.1016" r="6.39844" stroke={props.color} strokeWidth="1.25" />
      <circle cx="33.6016" cy="14.1016" r="6.39844" stroke={props.color} strokeWidth="1.25" />
      <circle cx="7.39844" cy="28.7266" r="6.39844" stroke={props.color} strokeWidth="1.25" />
    </SvgIcon>
  );
};

export default VoxIcon;
