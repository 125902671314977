import { FC, useEffect } from 'react';
import { Box, Button, CircularProgress, Divider, List, SxProps, Typography } from '@mui/material';
import { useNotificationActions, useNotification, useNotificationState } from 'src/state/hooks';
import NotificationItem from './NotificationItem';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { Notification } from 'src/types';

interface NotificationListProps {
  containerStyle?: SxProps;
}

const NotificationList: FC<NotificationListProps> = ({ containerStyle }) => {
  const { fetchNotification } = useNotificationActions();
  const notifications = useNotification();
  const { processing, page, pageSize, totalPages, total, countUnread } = useNotificationState();
  const handleError = useErrorHandler();

  const handleLoadMore = async () => {
    await fetchNotification({
      page: page + 1,
      pageSize,
      total,
      totalPages
    });
  };

  useEffect(() => {
    const fetch = async () => {
      if ((notifications || []).length === 0) {
        try {
          await fetchNotification({
            page,
            pageSize,
            total,
            totalPages
          });
        } catch (err) {
          handleError(err);
        }
      }
    };
    fetch();
  }, []);

  return (
    <Box>
      <Divider />
      {notifications.length === 0 ? (
        <Box sx={{ p: 2 }}>
          <Typography color="textPrimary" variant="subtitle2">
            There are no notifications
          </Typography>
        </Box>
      ) : (
        <Box sx={{ ...containerStyle }}>
          <List disablePadding>
            {notifications.map((notification: Notification, index: number) => (
              <Box key={`${notification.id}-${index}`}>
                <NotificationItem notification={notification} />
                <Divider />
              </Box>
            ))}
          </List>

          {totalPages > 1 && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1
              }}
            >
              <Button
                color="primary"
                size="small"
                disabled={total <= pageSize * page}
                onClick={handleLoadMore}
                sx={{ padding: '10px' }}
                startIcon={processing ? <CircularProgress size="1rem" color="secondary" /> : null}
              >
                Load more
              </Button>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default NotificationList;
