import { AxiosRequestHeaders } from 'axios';

export default class ApiTokenSupport {
  constructor(protected apiToken: string) {}

  protected withAuthorization(headers?: AxiosRequestHeaders): AxiosRequestHeaders {
    return {
      ...(headers || {}),
      Authorization: `Bearer ${this.apiToken}`
    };
  }
}
