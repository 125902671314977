import { FC, ComponentType, Component as ReactComponent, PropsWithChildren } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { AuthorizationProvider } from 'src/contexts/AuthorizationContext';
import useAuthorization from 'src/hooks/useAuthorization';
import NoRightView from 'src/pages/NoRightView';
import SuspenseLoader from 'src/components/SuspenseLoader';

interface AccessGuardProps {}

const AccessGuard: FC<PropsWithChildren<AccessGuardProps>> = ({ children }) => {
  const { authorization } = useAuthorization();

  if (!authorization) {
    return <SuspenseLoader />;
  }

  if (!authorization.isMember()) {
    return <NoRightView />;
  }

  return <>{children || <Outlet />}</>;
};

const withAuthorization = <P extends object>(Component: ComponentType<P>) =>
  class WithAuthorization extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <AuthorizationProvider>
          <Component {...(props as P)} />
        </AuthorizationProvider>
      );
    }
  };

export default withAuthorization(AccessGuard);
