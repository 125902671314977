import axios from 'src/utils/axios';
import { Community, Invitation, Membership, Member, MagicLink, WalletAddress, Notification, PaginatedMetadata } from 'src/types';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class NotificationApi extends ApiTokenSupport {
  /**
   * Create a new Notification
   *
   * @param payload
   * @returns
   */
  async create(payload: Notification): Promise<Notification> {
    const resp = await axios.post<Notification>('/api/notification/create', payload, this.withAuthorization());

    return resp.data;
  }

  /**
   * Mark a notification as read
   *
   * @param notification_id
   * @returns the count of unread
   */
  async markNotificationAsRead(notification_id: Notification['id'], read: boolean = true): Promise<number> {
    const resp = await axios.post<number>('/api/notification/read', { notification_id, read }, this.withAuthorization());

    return resp.data;
  }

  /**
   * Mark all notification as read
   *
   * @returns
   */
  async markAllNotificationAsRead(): Promise<boolean> {
    const resp = await axios.post<boolean>('/api/notification/readAll', {}, this.withAuthorization());

    return resp.data;
  }

  /**
   * restore notification icon
   *
   * @returns
   */
  async readNotificationIcon(): Promise<number> {
    const resp = await axios.post<number>('/api/notification/readNotificationIcon', {}, this.withAuthorization());
    return resp.data;
  }

  /**
   * Retrieve user's notifications
   * @returns
   */
  async fetch(pagination?: PaginatedMetadata): Promise<Notification[]> {
    const resp = await axios.get<Notification[]>(
      `/api/notification/list?page=${pagination.page}&pageSize=${pagination.pageSize}`,
      this.withAuthorization()
    );

    return resp.data;
  }
}

export default new NotificationApi(s.recursive('jwt'));
