import Alert from 'src/components/Alert';
import useBilling from 'src/hooks/useBilling';

interface SubscriptionModalLimitProps {
  open: boolean;
  message: string;
  onCancel?: () => void;
}

const SubscriptionModalLimit = ({ message, open, onCancel }: SubscriptionModalLimitProps) => {
  const { openBilling } = useBilling();

  return (
    <Alert open={open} title="Subscription Limit" text={message} textConfirm="Update Plan" onConfirm={() => openBilling(true)} onCancel={onCancel} />
  );
};

export default SubscriptionModalLimit;
