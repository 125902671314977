import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Community, LoadableState, Member } from 'src/types';
import { createCommunity, fetchCommunities, fetchCommunityMembers, getCommunityById, updateCommunity } from '../actions/community';
import { mergeArraysBy } from 'src/utils/common';

interface CommunityState extends LoadableState<Community[]> {}

const initialState: CommunityState = {
  isLoading: false,
  values: []
};

const slice = createSlice({
  name: 'communities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createCommunity.fulfilled, (state: CommunityState, action: PayloadAction<Community>) => {
      state.values = [...[...state.values], action.payload];
    });
    builder.addCase(getCommunityById.fulfilled, (state: CommunityState, action: PayloadAction<Community>) => {
      state.values = mergeArraysBy('id', [...state.values], [action.payload]);
    });
    builder.addCase(fetchCommunities.fulfilled, (state: CommunityState, action: PayloadAction<Community[]>) => {
      state.values = mergeArraysBy('id', [...state.values], action.payload);
      state.loaded = true;
    });
    builder.addCase(fetchCommunityMembers.fulfilled, (state: CommunityState, action: PayloadAction<Member[], 'fullfiled', any>) => {
      const community_id = action.meta.arg;
      let newStateValues = [];
      for (const community of [...state.values]) {
        if (community.id === community_id) {
          community.members = [...action.payload];
          community.loadedMembers = true;
        }
        newStateValues = [...newStateValues, community];
      }
      state.values = [...newStateValues];
    });
    builder.addCase(updateCommunity.fulfilled, (state, action: PayloadAction<Community>) => {
      state.values = state.values.map((community) => (community.id === action.payload.id ? { ...community, ...action.payload } : community));
    });
  }
});

export const reducer = slice.reducer;
export default slice;
