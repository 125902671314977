import { FC, PropsWithChildren, useState, createContext, ReactNode } from 'react';
import { Invitation } from 'src/types';

interface CreateCommunityContextValue {
  communityId?: number;
  values?: {
    file: number;
    name: string;
    description: string;
  };
  members: Invitation[];
  fileName: string;
  setFileName?: (f: string) => void;
  setMembers?: (invitations: Invitation[]) => void;
  setValues?: (key: string, v: string) => void;
  resetValues?: () => void;
  setCommunityId?: (c: number) => void;
}

export const CreateCommunityContext = createContext<CreateCommunityContextValue>({
  values: null,
  members: [],
  fileName: ''
});

export const CreateCommunityProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [communityId, setCommunityId] = useState(0);
  const [values, setValues] = useState(null);
  const [fileName, setFileName] = useState('');
  const [members, setMembers] = useState<Invitation[]>([]);

  const _setValues = (key: string, v: string) => setValues({ ...values, [key]: v });
  const resetValues = () => setValues(null);

  return (
    <CreateCommunityContext.Provider
      value={{ communityId, values, setValues: _setValues, resetValues, setMembers, members, setFileName, fileName, setCommunityId }}
    >
      {children}
    </CreateCommunityContext.Provider>
  );
};
