import { FC } from 'react';
import { Box, List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import { MenuItem, SidebarMenuProps } from 'src/types/sidebar';
import { styled } from '@mui/material/styles';
import useSidebarMenu from 'src/hooks/useSidebarMenu';

const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 0;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 54px;
          height: 54px;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 8px;
            top: 12px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            svg {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

const renderSidebarMenuItems = ({ items, path }) => (
  <SubMenuWrapper>
    <List component="div" sx={{ mt: items.length > 2 ? '100%' : 30 }}>
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </List>
  </SubMenuWrapper>
);

const reduceChildRoutes = ({ ev, path, item }) => {
  const key = item.name;

  const exactMatch =
    item.link && typeof item.link === 'string'
      ? !!matchPath(
          {
            path: item.link,
            end: true
          },
          path
        )
      : false;

  const highlightMatch = (item.highlightLinks || []).map((link: string) =>
    link
      ? !!matchPath(
          {
            path: link,
            end: false
          },
          path
        )
      : false
  );

  if (item.items) {
    const partialMatch =
      item.link && typeof item.link === 'string'
        ? !!matchPath(
            {
              path: item.link,
              end: false
            },
            path
          )
        : false;

    ev.push(
      <SidebarMenuItem key={key} active={partialMatch} open={partialMatch} name={item.name} icon={item.icon} link={item.link}>
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    ev.push(
      <SidebarMenuItem
        key={key}
        active={exactMatch || highlightMatch.includes(true)}
        name={item.name}
        link={item.link}
        icon={item.icon}
        iconStyle={item.iconStyle}
        disabled={item.disabled}
      />
    );
  }

  return ev;
};

const SidebarMenu: FC<SidebarMenuProps> = () => {
  const location = useLocation();
  const { menuItem } = useSidebarMenu();
  return (
    <>
      {[menuItem].map((section: MenuItem) => (
        <MenuWrapper key={section.heading}>
          <List component="div">
            {renderSidebarMenuItems({
              items: section.items,
              path: location.pathname
            })}
          </List>
        </MenuWrapper>
      ))}
    </>
  );
};

export default SidebarMenu;
