import axios from 'src/utils/axios';
import { ActiveUser, User, UserUpdateParams } from 'src/types';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class UserApi extends ApiTokenSupport {
  /**
   * Login
   *
   * @param email string
   * @param password string
   * @returns
   */
  async login(email: string, password: string): Promise<{ jwt: string; user: User }> {
    const resp = await axios.post<{ jwt: string; user: User }>('/api/users/login', { email, password });

    return resp.data;
  }

  /**
   * Register
   *
   * @param email string
   * @param name string
   * @param password string
   * @returns
   */
  async register(email: string, firstname: string, password: string, lastname?: string, community_id?: string): Promise<{ jwt: string; user: User }> {
    const resp = await axios.post<{ jwt: string; user: User }>('/api/users/register', { email, password, firstname, lastname, community_id });

    return resp.data;
  }

  /**
   * Update user basic info
   *
   * @param user_id
   * @param data
   * @returns
   */
  async updateUser(user_id: number, data: UserUpdateParams): Promise<User> {
    const resp = await axios.put<User>(`/api/users/${user_id}`, data);

    return resp.data;
  }

  /**
   * Update user password
   * @param user_id number
   * @param newPassword string
   * @returns
   */
  async updatePassword(newPassword: string): Promise<boolean> {
    const resp = await axios.put<boolean>(`/api/users/updatePassword`, { newPassword });

    return resp.data;
  }

  /**
   * Recover password
   * @param email
   * @returns
   */
  async recoverPassword(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/users/password-recovery`, { email });

    return resp.data;
  }

  /**
   * Reset password
   * @param email
   * @returns
   */
  async resetPassword(newPassword: string, token: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/users/password-reset`, { newPassword, token });

    return resp.data;
  }

  /**
   * Create or Update the status of the user.
   * @param status
   * @returns
   */
  async updateActiveUser(payload: Partial<ActiveUser>): Promise<boolean> {
    const resp = await axios.post(`api/active_users/update`, { ...payload }, this.withAuthorization());

    return resp.data !== null;
  }

  /**
   * Fetch all active users inside voxiberation
   * @param ask_id
   * @returns {ActiveUser[]}
   */
  async getAllActiveUsers(ask_id: number, user_id: number): Promise<ActiveUser[]> {
    let resp = await axios.get<ActiveUser[]>(`api/active_users/${ask_id.toString()}/active`, this.withAuthorization());

    // We need to take into account that we should not count the current user.
    resp.data = resp.data.filter((item) => item.user_id !== user_id);

    return resp.data;
  }

  /**
   * Fetch all active users during the phase
   * @param ask_id
   * @param phase
   * @returns {ActiveUser[]}
   */
  async getUserActivePhase(ask_id: number, phase: string, user_id: number): Promise<ActiveUser[]> {
    let resp = await axios.get<ActiveUser[]>(`api/active_users/user-active-phase/${ask_id.toString()}/${phase}`, this.withAuthorization());

    resp.data = resp.data.filter((item) => item.user_id !== user_id);

    return resp.data;
  }

  /**
   * Get the status of the user
   * @param ask_id
   * @param ask_id
   * @returns {ActiveUser}
   */
  async getUserStatus(ask_id: number, user_id: number): Promise<ActiveUser> {
    let resp = await axios.get<ActiveUser>(`api/active_users/${ask_id.toString()}/${user_id.toString()}`, this.withAuthorization());
    return resp.data;
  }

  /**
   * Send magic link to login
   * @param email
   * @returns
   */
  async sendLoginMagicLink(email: string, invite?: string, community_id?: number): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/users/magic_link/login`, { email, invite, community_id });

    return resp.data;
  }

  /**
   * Send magic link to register
   * @param email
   * @returns
   */
  async sendRegitrationMagicLink(data: User, community_id?: number, invite?: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/users/magic_link/register`, { ...data, community_id, invite });

    return resp.data;
  }

  /**
   * Send magic link to login
   * @param email
   * @returns
   */
  async verifyMagicLink(token: string): Promise<{ user: User; jwt: string }> {
    const resp = await axios.get<{ user: User; jwt: string }>(`/api/users/magic_link/verify/${token}`);

    return resp.data;
  }
  /**
   * Resend magic link
   * @param email
   * @param purpose
   * @returns
   */
  async resendLink(email: string, purpose: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/users/magic_link/resend`, { email, purpose });

    return resp.data;
  }
}

export default new UserApi(s.recursive('jwt'));
