import type { FC, PropsWithChildren } from 'react';
import { storage as s } from 'src/utils/storage';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import { Outlet } from 'react-router-dom';
import { listenNewNotificationMessage } from 'src/faye/listener';
import { destructureCryptedParams } from 'src/utils/address';

interface AuthGuardProps {}

const AuthGuard: FC<PropsWithChildren<AuthGuardProps>> = ({ children }) => {
  const { isAuthenticated /* setRedirection */ } = useAuth();
  const invite_session = s.recursive('invite_session');

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const encryptedParams = searchParams.get('origin');

  const { redirection } = destructureCryptedParams(encryptedParams);

  const path = redirection === 'registration_account' ? 'register' : 'login';

  const params_prefilled = encryptedParams ? `&origin=${encryptedParams}` : '';

  if (!isAuthenticated && invite_session) {
    return <Navigate to={`/${path}?invite=${invite_session}${params_prefilled}`} />;
  }

  if (!isAuthenticated) {
    return <Navigate to={`/${path}?authenticated=no${params_prefilled}`} />;
  }

  // Listen new notification
  listenNewNotificationMessage();

  return <>{children || <Outlet />}</>;
};

export default AuthGuard;
