import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';
import { Comment, CommentRanking, CommentStream } from 'src/types';
import { MemberChat } from 'src/contexts/VoxiberationContext';

class CommentApi extends ApiTokenSupport {
  async postComment(comment: Comment): Promise<Comment> {
    const resp = await axios.post(`/api/comment`, comment, this.withAuthorization());

    return resp.data;
  }

  async postCommentStream(
    comment: Pick<
      CommentStream & { unpublish: boolean; created_by: number },
      'type' | 'text' | 'ask_id' | 'from' | 'to' | 'unpublish' | 'created_by' | 'chat_stream' | 'phase'
    >
  ): Promise<Comment> {
    const resp = await axios.post(`/api/comment_stream`, comment, this.withAuthorization());

    return resp.data;
  }

  async moderate(text: string): Promise<boolean> {
    const resp = await axios.post(`/api/comment/moderate`, { text });
    return resp.data;
  }

  async fetchCommentStream(ask_id: string, chat_stream?: string): Promise<CommentStream[]> {
    const resp = await axios.get(`/api/comment/stream/${ask_id}/${chat_stream}`, this.withAuthorization());

    return resp.data;
  }

  async fetchMembersChat(ask_id: string): Promise<MemberChat[]> {
    const resp = await axios.get(`/api/comment/members/${ask_id}`, this.withAuthorization());

    return resp.data;
  }

  async fetchMemberStream(ask_id: string, member_id: number): Promise<CommentStream[]> {
    const resp = await axios.get(`/api/comment/members_stream/${ask_id}/${member_id}`, this.withAuthorization());

    return resp.data;
  }

  async fetchDeliberationChoice(ask_id: number, phase: number): Promise<{ choices: CommentStream[]; nonProcessedPairs: CommentStream[][] }> {
    const resp = await axios.post(`/api/comment/deliberation-choice`, { ask_id, phase }, this.withAuthorization());

    return resp.data;
  }

  async postDeliberationChoice(
    ask_id: number,
    phase: number,
    user_id: number,
    theme_id: number,
    comment_id: number,
    top: number,
    bottom: number
  ): Promise<any> {
    const resp = await axios.post(`/api/voxiberation`, { ask_id, phase, user_id, theme_id, comment_id, top, bottom }, this.withAuthorization());

    return resp.data;
  }

  async fetchTop5CommentRanking(ask_id: number): Promise<CommentStream[]> {
    const resp = await axios.get(`/api/comment/top5-ranking/${ask_id}`, this.withAuthorization());

    return resp.data;
  }

  async updateTop5CommentRanking(comments: CommentRanking[]): Promise<CommentRanking[]> {
    const resp = await axios.post(`/api/comment-ranking/update-rankings`, { commentRankings: comments }, this.withAuthorization());

    return resp.data;
  }
}

export default new CommentApi(s.recursive('jwt'));
