import axios from 'src/utils/axios';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class VoxApi extends ApiTokenSupport {
  /**
   * Get the user VOX balance
   * @param user_id
   * @returns {number}
   */
  async getUserVoxBalance(user_id: number): Promise<number> {
    let resp = await axios.get<number>(`api/vox/balance/${user_id.toString()}`, this.withAuthorization());
    return resp.data;
  }
}

export default new VoxApi(s.recursive('jwt'));
