import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useSnackbar } from 'notistack';
import axios from 'src/utils/axios';
import jwtDecode from 'jwt-decode';
import { User, UserUpdateParams } from 'src/types/user';
import { storage as s } from '../utils/storage';
import { userApi } from 'src/api';
import { useAskAction, useCommunityActions } from 'src/state/hooks';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isNew: boolean;
  user: User & { vox?: number };
  redirectTo?: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  initialize: () => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  loginViaMaginLink: (email: string, invite_session?: string, community_id?: number) => Promise<void>;
  registerViaMaginLink: (data: User, community_id?: number, invite_session?: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string, lastname?: string, community_id?: string) => Promise<void>;
  setRedirection?: (url: string) => void;
  updateUser: (user_id: number, payload: UserUpdateParams) => Promise<void>;
  updatePassword: (newPassword: string) => Promise<void>;
  verifyLoginToken: (token: string) => Promise<void>;
  resendLink: (email: string, purpose: string) => Promise<void>;
}

const initialAuthState: AuthState = {
  isInitialized: false,
  isAuthenticated: false,
  isNew: false,
  user: null
};

type InitialiseAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    user: User;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
  payload?: {};
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type RedirectAction = {
  type: 'REDIRECT';
  payload: string;
};

type UpdateUserAction = {
  type: 'UPDATE_USER';
  payload: {
    user: User;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction | RedirectAction | UpdateUserAction;

const setSession = (jwt: string | null): void => {
  if (jwt) {
    s.record('jwt', jwt);
    axios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
  } else {
    // s.record('jwt', null);
    s.flush();
    delete axios.defaults.headers.common.Authorization;
  }
};

const isValidToken = (jwt: string): boolean => {
  if (!jwt) {
    return false;
  }
  const decoded: any = jwtDecode(jwt);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitialiseAction): AuthState => {
    const { user, isAuthenticated } = action.payload;
    return {
      ...state,
      isInitialized: true,
      isAuthenticated,
      user
    };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: AuthState, action: RegisterAction) => {
    const { user } = action.payload;
    return {
      ...state,
      isAuthenticated: true,
      isNew: true,
      user
    };
  },
  REDIRECT: (state: AuthState, action: RedirectAction) => {
    return {
      ...state,
      redirectTo: action.payload
    };
  },
  UPDATE_USER: (state: AuthState, action: UpdateUserAction) => ({
    ...state,
    user: {
      ...state.user,
      ...action.payload.user
    }
  })
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  initialize: () => Promise.resolve(),
  loginViaMaginLink: () => Promise.resolve(),
  registerViaMaginLink: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  updateUser: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
  verifyLoginToken: () => Promise.resolve(),
  resendLink: () => Promise.resolve()
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const initialize = async () => {
    try {
      const jwt = s.recursive('jwt');

      if (jwt && isValidToken(jwt)) {
        setSession(jwt);

        const response = await axios.get('/api/users/me');
        const user = response.data;

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            user
          }
        });
      } else {
        setSession(null);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const setRedirection = (url: string) => {
    dispatch({
      type: 'REDIRECT',
      payload: url
    });
  };

  const login = async (email: string, password: string) => {
    try {
      const { jwt, user } = await userApi.login(email, password);

      setSession(jwt);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to login', { variant: 'error' });
    }
  };

  const loginViaMaginLink = async (email: string, invite_session?: string, community_id?: number) => {
    try {
      await userApi.sendLoginMagicLink(email, invite_session, community_id);
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to process the login', { variant: 'error' });
      throw err;
    }
  };

  const registerViaMaginLink = async (data: User, community_id?: number, invite?: string) => {
    try {
      await userApi.sendRegitrationMagicLink(data, community_id, invite);
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to process the registration', { variant: 'error' });
      throw err;
    }
  };

  const verifyLoginToken = async (token: string) => {
    try {
      const { jwt, user } = await userApi.verifyMagicLink(token);

      setSession(jwt);
      dispatch({
        type: 'LOGIN',
        payload: {
          user
        }
      });
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to login', { variant: 'error' });
      throw err;
    }
  };

  const resendLink = async (email: string, purpose: string) => {
    try {
      await userApi.resendLink(email, purpose);
      enqueueSnackbar('New link sent!', { variant: 'success' });
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to resend link', { variant: 'error' });
    }
  };

  const logout = async () => {
    setSession(null);
    setRedirection(null);
    dispatch({ type: 'LOGOUT' });
  };

  const register = async (email: string, firstname: string, password: string, lastname?: string, community_id?: string) => {
    try {
      const { jwt, user } = await userApi.register(email, firstname, password, lastname, community_id);

      s.record('jwt', jwt);
      dispatch({
        type: 'REGISTER',
        payload: {
          user
        }
      });
      setSession(jwt);
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to register', { variant: 'error' });
    }
  };

  const updateUser = async (user_id: number, payload: UserUpdateParams) => {
    try {
      const updateUser = await userApi.updateUser(user_id, payload);

      if (updateUser) {
        dispatch({
          type: 'UPDATE_USER',
          payload: {
            user: { ...updateUser }
          }
        });
      }
    } catch (err) {
      closeSnackbar();
      enqueueSnackbar(err.message || 'Unable to register', { variant: 'error' });
    }
  };

  const updatePassword = async (newPassword: string): Promise<void> => {
    const isUpdated = await userApi.updatePassword(newPassword);
    if (!isUpdated) {
      closeSnackbar();
      enqueueSnackbar('Unable to update your password', { variant: 'error' });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        initialize,
        login,
        loginViaMaginLink,
        registerViaMaginLink,
        logout,
        register,
        setRedirection,
        updateUser,
        updatePassword,
        verifyLoginToken,
        resendLink
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
