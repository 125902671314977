import { FC, PropsWithChildren, useState, createContext, ReactNode, useEffect } from 'react';
import { Step } from 'src/components/CustomStepper';

interface StepperContextValue {
  currentStep: number;
  steps: Step[];
  setActiveStep: (step: number | string) => void;
  setSteps: (steps: Step[]) => void;
}

export const StepperContext = createContext<StepperContextValue>({
  currentStep: null,
  steps: [],
  setActiveStep: () => {},
  setSteps: () => {}
});

interface StepperContextProps {
  steps: Step[];
}

export const StepperProvider: FC<PropsWithChildren<StepperContextProps>> = ({ steps, children }) => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [_steps, setSteps] = useState<Step[]>(steps);

  const _setCurrentStep = (step: number) => {
    setCurrentStep(step);
  };

  useEffect(() => {
    setSteps(steps);
  }, [steps]);

  return (
    <StepperContext.Provider value={{ currentStep, steps: _steps, setActiveStep: _setCurrentStep, setSteps }}>{children}</StepperContext.Provider>
  );
};
