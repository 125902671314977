import { FC, PropsWithChildren, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useStepper from 'src/hooks/useStepper';
import { Outlet } from 'react-router-dom';
import withCommunityStepper from 'src/pages/Community/CommunityStepper';
import useCreateCommunity from 'src/hooks/useCreateCommunity';

interface StepAskGuardProps {}

const StepAskGuard: FC<PropsWithChildren<StepAskGuardProps>> = ({ children }) => {
  /* const { setSteps } = useStepper();
  const { community_id } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { communityId } = useCreateCommunity();

  const d = searchParams.get('d');

  useEffect(() => {
    if (d) {
      const steplink = (step: number) => {
        return `/community/${community_id}/ask?step=${step}&${d ? 'd=true' : ''}`;
      };
      // The cb will be called when clicking on the stepper
      const step = [
        { key: 3, label: 'set up Ask', cb: { fn: navigate, args: [steplink(1)] } },
        { key: 4, label: 'proposal', cb: { fn: navigate, args: [steplink(2)] } },
        { key: 5, label: 'learning materials', cb: { fn: navigate, args: [steplink(3)] } },
        { key: 6, label: 'rewards', cb: { fn: navigate, args: [steplink(4)] } }
      ];
      setSteps(step);
    }
  }, [communityId, community_id]); */

  return <>{children || <Outlet />}</>;
};

export default withCommunityStepper(StepAskGuard);
