import { useSnackbar } from 'notistack';

const useErrorHandler = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleError = (error: Error) => {
    closeSnackbar();
    enqueueSnackbar(error.message || 'An error occured', { variant: 'error' });
  };

  return handleError;
};

export default useErrorHandler;
