import React, { useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { ThemeProvider as MuiThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { themeCreator } from './base';

export const ThemeContext = React.createContext((themeName: string): void => {});

interface ThemeProviderProps {}

const ThemeProvider: FC<PropsWithChildren<ThemeProviderProps>> = ({ children }: PropsWithChildren<ThemeProviderProps>) => {
  const curThemeName = 'LightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = themeCreator(themeName);
  const setThemeName = (themeName: string): void => {
    _setThemeName(themeName);
  };
  return (
    <StyledEngineProvider injectFirst>
      <ThemeContext.Provider value={setThemeName}>
        <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
      </ThemeContext.Provider>
    </StyledEngineProvider>
  );
};

export default ThemeProvider;
