import { FC, PropsWithChildren, useState, createContext, useEffect } from 'react';

interface RedirectionContextValue {
  redirectTo?: string;
  setRedirection: (url: string) => void;
}

export const RedirectionContext = createContext<RedirectionContextValue>({
  redirectTo: null,
  setRedirection: () => {}
});

interface RedirectionContextProps {}

export const RedirectionProvider: FC<PropsWithChildren<RedirectionContextProps>> = ({ children }) => {
  const [redirectTo, setRedirection] = useState<string>();

  return <RedirectionContext.Provider value={{ redirectTo, setRedirection }}>{children}</RedirectionContext.Provider>;
};
