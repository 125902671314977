import type { FC, PropsWithChildren } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { SnackbarProvider } from 'notistack';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import ThemeProvider from '../theme/ThemeProvider';
import { AuthProvider } from '../contexts/AuthContext';
import { HeaderProvider } from 'src/contexts/HeaderContext';
import { RedirectionProvider } from 'src/contexts/RedirectionContext';

import { Provider as ReduxProvider } from 'react-redux';
import store from 'src/state/store';
import { FayeProvider } from 'src/faye';
import { IntroJSProvider } from 'src/contexts/IntroJsContext';

interface AppProviderProps {}

const AppProvider: FC<PropsWithChildren<AppProviderProps>> = ({ children }) => (
  <HelmetProvider>
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StyledEngineProvider injectFirst>
        <BrowserRouter>
          <ReduxProvider store={store}>
            <ThemeProvider>
              <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} autoHideDuration={3000}>
                <AuthProvider>
                  <RedirectionProvider>
                    <HeaderProvider>
                      <IntroJSProvider>
                        <FayeProvider>{children}</FayeProvider>
                      </IntroJSProvider>
                    </HeaderProvider>
                  </RedirectionProvider>
                </AuthProvider>
              </SnackbarProvider>
            </ThemeProvider>
          </ReduxProvider>
        </BrowserRouter>
      </StyledEngineProvider>
    </LocalizationProvider>
  </HelmetProvider>
);

export default AppProvider;
