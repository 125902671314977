import axios from 'src/utils/axios';
import { Material, MaterialFile } from 'src/types/material';
import { storage as s } from 'src/utils/storage';
import ApiTokenSupport from './token.support';

class MaterialApi extends ApiTokenSupport {
  async resolveFileContent(id: number): Promise<string> {
    const resp = await axios.get(`/api/material/resolveFileContent/${id}`, this.withAuthorization());

    return resp.data;
  }

  async getAskMaterial(ask_id: number): Promise<MaterialFile[]> {
    const resp = await axios.get(`/api/material/ask/${ask_id}`, this.withAuthorization());

    return resp.data;
  }

  async addAskMaterial(material: Material): Promise<Material> {
    const resp = await axios.post(`/api/material`, material, this.withAuthorization());

    return resp.data;
  }

  async deleteAskMaterial(materialId: number): Promise<void> {
    const resp = await axios.delete(`/api/material/${materialId}`, this.withAuthorization());

    return resp.data;
  }
}

export default new MaterialApi(s.recursive('jwt'));
