import { storage as s } from 'src/utils/storage';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import omit from 'lodash.omit';
import { RootState, useSelector, useDispatch } from '../store';
import {
  createCommunity,
  updateCommunity,
  verifyInvitation,
  getCommunityById,
  acceptInvitation,
  fetchCommunities,
  fetchCommunityMembers,
  deleteMemberFromCommunity,
  deleteCommunityInvitation,
  inviteMembers,
  verifyMagicLink,
  acceptViaMagicLink,
  deleteCommunityFakeUser
} from '../actions/community';
import { Community, Invitation } from 'src/types';
import useErrorHandler from 'src/hooks/useErrorHandler';
import { useSnackbar } from 'notistack';

interface ProcessingErrorState {
  error?: Error;
  processing?: boolean;
}

interface InvitationFlowParams {
  isAuthenticated?: boolean;
  community_id: number;
}

interface InvitationState extends ProcessingErrorState {
  canAccept?: boolean;
  accepted?: boolean;
}

interface InvitationMagicState extends InvitationState {
  community_id?: number;
}

export const useCommunityActions = () => {
  const dispatch = useDispatch();

  return {
    /**
     * Create a new community, this will dispatch `createCommunity` action
     *
     * @param payload
     * @returns
     */
    createCommunity: (payload: Community) => dispatch(createCommunity(payload)).unwrap(),
    /**
     * Update a community by its id, this will dispatch `updateCommunity` action
     *
     * @param payload
     * @returns
     */
    updateCommunity: (id: Community['id'], payload: Partial<Community>) => dispatch(updateCommunity({ id, payload })).unwrap(),
    /**
     * Fetch community for the connected user
     * @returns
     */
    fetchCommunities: () => dispatch(fetchCommunities()).unwrap(),
    /**
     * Fetch community members
     * @param id
     * @returns
     */
    fetchCommunityMembers: (id: number) => dispatch(fetchCommunityMembers(id)).unwrap(),
    /**
     * Get a community by id
     * @param id
     * @returns
     */
    getCommunityById: (id: number) => dispatch(getCommunityById(id)).unwrap()
  };
};

/**
 * Handle whole invitation flow
 *
 * @param params
 * @returns
 */
export const useInvitationFlow = (params: InvitationFlowParams): [InvitationState, () => void] => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [state, setState] = useState<InvitationState>({ processing: true });

  const triggerAcceptInvitation = () => {
    if (!state.canAccept) {
      // only user that are checked to accept can
      return;
    }

    setState((prev) => ({ ...prev, processing: true }));
    dispatch(acceptInvitation(params.community_id))
      .unwrap()
      .then((accepted) => {
        setState((prev) => ({ ...prev, accepted }));
        setTimeout(() => {
          // navigateTo(`/community/${params.community_id}`);
          window.location.href = `/community/${params.community_id}`; // force reload (like to get the avatar)
        }, 1500);
      })
      .catch((e) => setState((prev) => ({ ...prev, error: e })))
      .finally(() => setState((prev) => ({ ...prev, processing: false })));
  };

  useEffect(() => {
    // when authenticated, verify that the user have an invitation to join the community
    if (params.isAuthenticated) {
      dispatch(verifyInvitation(params.community_id))
        .unwrap()
        .then((canAccept) => setState((prev) => ({ ...prev, canAccept })))
        .catch((e) => setState((prev) => ({ ...prev, error: e })))
        .finally(() => setState((prev) => ({ ...prev, processing: false })));
    }
  }, [params.isAuthenticated]);

  return [state, triggerAcceptInvitation];
};

export const useInvitationMagicFlow = ({ isAuthenticated, token }): [InvitationMagicState, () => void] => {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();
  const [state, setState] = useState<InvitationMagicState>({ processing: true });

  const triggerAcceptInvitation = useCallback(() => {
    if (!state.canAccept) {
      // only user that are checked to accept can
      return;
    }

    setState((prev) => ({ ...prev, processing: true }));
    dispatch(acceptViaMagicLink({ community_id: state.community_id, token }))
      .unwrap()
      .then((accepted) => {
        setState((prev) => ({ ...prev, accepted }));
        setTimeout(() => {
          // navigateTo(`/community/${state.community_id}`);
          window.location.href = `/community/${state.community_id}`; // force reload (like to get the avatar)
        }, 1500);
      })
      .catch((e) => setState((prev) => ({ ...prev, error: e })))
      .finally(() => {
        setState((prev) => ({ ...prev, processing: false }));
        s.remove('invite_session');
      });
  }, [state.community_id, token]);

  useEffect(() => {
    // when authenticated, verify that the user have an invitation to join the community
    if (isAuthenticated) {
      dispatch(verifyMagicLink(token))
        .unwrap()
        .then((row) => setState((prev) => ({ ...prev, canAccept: !!row, community_id: row.community_id })))
        .catch((e) => setState((prev) => ({ ...prev, error: e })))
        .finally(() => setState((prev) => ({ ...prev, processing: false })));
    }
  }, [isAuthenticated, token]);

  return [state, triggerAcceptInvitation];
};

export const useCommunities = () => {
  const { fetchCommunities } = useCommunityActions();
  const { values: communities, loaded } = useSelector((state: RootState) => state.communities);

  useEffect(() => {
    if (!loaded) {
      fetchCommunities();
    }
  }, []);

  return communities;
};

export const useCommunityState = () => {
  return useSelector((state: RootState) => omit(state.communities, 'values'));
};

export const useCommunityById = (id: number, withMembers?: boolean) => {
  const handleError = useErrorHandler();
  const { getCommunityById } = useCommunityActions();
  const { fetchCommunityMembers } = useCommunityActions();
  const storeCommunity = useSelector((state: RootState) => state.communities.values.filter((c: Community) => c.id === id).pop());

  useEffect(() => {
    if (!id) {
      return;
    }
    // If not yet present in the store
    if (!storeCommunity) {
      getCommunityById(id).catch((err) => handleError(err));
    }

    if (!storeCommunity?.loadedMembers && withMembers) {
      fetchCommunityMembers(id).catch((err) => handleError(err));
    }
  }, [storeCommunity, id, withMembers]);

  return storeCommunity;
};

export const useCommunity = (id: number) => {
  const { getCommunityById } = useCommunityActions();
  const handleError = useErrorHandler();
  const [community, setCommunity] = useState<Community>();

  useEffect(() => {
    if (!id) {
      return;
    }
    getCommunityById(id)
      .then((v) => setCommunity(v))
      .catch((err) => handleError(err));
  }, [id]);

  return community;
};

export const useCommunityMemberActions = (community_id: number) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<ProcessingErrorState>({ processing: false });
  const { enqueueSnackbar } = useSnackbar();

  const deleteFromCommunity = (id: number, model: string) => {
    setState((prev) => ({ ...prev, processing: true }));
    const action = model === 'simulation' ? deleteCommunityFakeUser : model === 'membership' ? deleteMemberFromCommunity : deleteCommunityInvitation;
    dispatch(action(id))
      .unwrap()
      .then((deleted: boolean) => {
        dispatch(fetchCommunityMembers(community_id));
      })
      .catch((e) => setState((prev) => ({ ...prev, error: e })))
      .finally(() => setState((prev) => ({ ...prev, processing: false })));
  };

  const sendReminder = (invitation: Invitation) => {
    setState((prev) => ({ ...prev, processing: true }));
    dispatch(inviteMembers({ invitations: [invitation], community_id }))
      .unwrap()
      .then(() => {
        enqueueSnackbar('Reminder sent successfully', { variant: 'success' });
        dispatch(fetchCommunityMembers(community_id));
      })
      .catch((e) => setState((prev) => ({ ...prev, error: e })))
      .finally(() => setState((prev) => ({ ...prev, processing: false })));
  };

  return { state, deleteFromCommunity, sendReminder };
};
