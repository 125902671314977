import { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AskIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 40 40">
      <path
        d="M25.6797 23.6758C25.2699 23.6758 24.9375 24.0081 24.9375 24.418V29.5391C24.9375 31.5853 23.2728 33.25 21.2266 33.25H13.2133C12.8036 33.25 12.4714 33.582 12.4711 33.9917L12.4701 35.4673L10.6986 33.6959C10.584 33.4327 10.3215 33.25 10.018 33.25H5.19531C3.1491 33.25 1.48438 31.5853 1.48438 29.5391V18.1094C1.48438 16.0632 3.1491 14.3984 5.19531 14.3984H12.6914C13.1012 14.3984 13.4336 14.0662 13.4336 13.6562C13.4336 13.2463 13.1012 12.9141 12.6914 12.9141H5.19531C2.33062 12.9141 0 15.2447 0 18.1094V29.5391C0 32.4038 2.33062 34.7344 5.19531 34.7344H9.6379L12.6861 37.7826C12.8281 37.9246 13.0179 38 13.2111 38C13.3066 38 13.403 37.9815 13.4947 37.9436C13.772 37.8289 13.9529 37.5584 13.9531 37.2583L13.9549 34.7344H21.2266C24.0913 34.7344 26.4219 32.4038 26.4219 29.5391V24.418C26.4219 24.0081 26.0895 23.6758 25.6797 23.6758Z"
        fill={props.color}
      />
      <path
        d="M27.2127 0H25.3343C19.3861 0 14.5469 4.83921 14.5469 10.7874C14.5469 16.7356 19.3861 21.5747 25.3342 21.5747H27.2126C28.141 21.5747 29.0599 21.4568 29.9505 21.2237L32.7253 23.997C32.8673 24.1388 33.057 24.2142 33.2501 24.2142C33.3458 24.2142 33.4423 24.1958 33.5341 24.1577C33.8114 24.0428 33.9922 23.7722 33.9922 23.4721V19.178C35.167 18.2266 36.1429 17.0248 36.8278 15.6822C37.6057 14.1573 38 12.5105 38 10.7874C38 4.83921 33.1608 0 27.2127 0ZM32.8034 18.2233C32.6174 18.3635 32.5078 18.583 32.5078 18.8162V21.681L30.6916 19.8658C30.5503 19.7246 30.3607 19.6485 30.1669 19.6485C30.0941 19.6485 30.0207 19.6593 29.949 19.6813C29.0655 19.9528 28.1449 20.0904 27.2127 20.0904H25.3343C20.2045 20.0904 16.0312 15.9171 16.0312 10.7874C16.0312 5.65769 20.2046 1.48438 25.3343 1.48438H27.2127C32.3424 1.48438 36.5156 5.65769 36.5156 10.7874C36.5156 13.7362 35.1626 16.4465 32.8034 18.2233Z"
        fill={props.color}
      />
      <path
        d="M29.6156 8.12104C29.5085 6.56727 28.256 5.31476 26.7022 5.20766C25.8213 5.14717 24.9807 5.44427 24.3373 6.04492C23.7028 6.63726 23.3389 7.4746 23.3389 8.34229C23.3389 8.7522 23.6712 9.08447 24.0811 9.08447C24.4909 9.08447 24.8232 8.7522 24.8232 8.34229C24.8232 7.87775 25.0104 7.44721 25.3502 7.13007C25.6897 6.81323 26.1334 6.65641 26.6002 6.68862C27.4187 6.74503 28.0784 7.40476 28.1348 8.22317C28.1918 9.04974 27.6495 9.7802 26.8454 9.96011C26.1939 10.1059 25.739 10.672 25.739 11.3368V13.1199C25.739 13.5298 26.0713 13.8621 26.4811 13.8621C26.891 13.8621 27.2233 13.5298 27.2233 13.1199V11.3961C28.7188 11.0348 29.7221 9.66635 29.6156 8.12104Z"
        fill={props.color}
      />
      <path
        d="M27.0062 15.5309C26.8681 15.3929 26.6766 15.3135 26.4814 15.3135C26.2862 15.3135 26.0948 15.3929 25.9567 15.5309C25.8187 15.669 25.7393 15.8605 25.7393 16.0557C25.7393 16.2516 25.8187 16.4431 25.9567 16.5811C26.0948 16.7192 26.2862 16.7979 26.4814 16.7979C26.6766 16.7979 26.8681 16.7192 27.0062 16.5811C27.1442 16.4423 27.2236 16.2516 27.2236 16.0557C27.2236 15.8605 27.1441 15.669 27.0062 15.5309Z"
        fill={props.color}
      />
      <path
        d="M20.4102 23.0078H4.75C4.34016 23.0078 4.00781 23.3401 4.00781 23.75C4.00781 24.1599 4.34016 24.4922 4.75 24.4922H20.4102C20.8201 24.4922 21.1523 24.1599 21.1523 23.75C21.1523 23.3401 20.82 23.0078 20.4102 23.0078Z"
        fill={props.color}
      />
      <path
        d="M20.9348 27.3815C20.7968 27.2435 20.6054 27.1641 20.4102 27.1641C20.215 27.1641 20.0235 27.2435 19.8854 27.3815C19.7473 27.5196 19.668 27.7111 19.668 27.9062C19.668 28.1014 19.7474 28.2929 19.8854 28.431C20.0235 28.569 20.215 28.6484 20.4102 28.6484C20.6054 28.6484 20.7968 28.569 20.9348 28.431C21.0729 28.2929 21.1523 28.1022 21.1523 27.9062C21.1523 27.7111 21.0729 27.5196 20.9348 27.3815Z"
        fill={props.color}
      />
      <path
        d="M17.4909 27.1641H4.75C4.34016 27.1641 4.00781 27.4963 4.00781 27.9062C4.00781 28.3162 4.34016 28.6484 4.75 28.6484H17.4909C17.9008 28.6484 18.2331 28.3162 18.2331 27.9062C18.2331 27.4963 17.9007 27.1641 17.4909 27.1641Z"
        fill={props.color}
      />
      <path
        d="M15.5859 18.8516H4.75C4.34016 18.8516 4.00781 19.1838 4.00781 19.5938C4.00781 20.0037 4.34016 20.3359 4.75 20.3359H15.5859C15.9958 20.3359 16.3281 20.0037 16.3281 19.5938C16.3281 19.1838 15.9958 18.8516 15.5859 18.8516Z"
        fill={props.color}
      />
    </SvgIcon>
  );
};

export default AskIcon;
