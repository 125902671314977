import { FC, ComponentType, Component as ReactComponent, PropsWithChildren, useState, useEffect } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { StepperProvider } from 'src/contexts/StepperContext';
import { CreateCommunityProvider } from 'src/contexts/CreateCommunityContext';
import useCreateCommunity from 'src/hooks/useCreateCommunity';

interface CommunityStepperProviderProps {}

const CommunityStepperProvider: FC<PropsWithChildren<CommunityStepperProviderProps>> = ({ children }) => {
  const navigate = useNavigate();
  const { community_id } = useParams();
  const { communityId } = useCreateCommunity();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const d = searchParams.get('d');

  const communitySteps = (id: number | string) => [
    { key: 1, label: 'community details', cb: { fn: navigate, args: [`/community/create${d ? '?d=true' : ''}`] } },
    { key: 2, label: 'invite members', cb: { fn: navigate, args: [`/community/${id}/invite${d ? '?d=true' : ''}`] } }
  ];

  const steplink = (id: number | string, step: number) => {
    return `/community/${community_id}/ask?step=${step}${d ? '&d=true' : ''}`;
  };

  // const askSteps = (id: number | string) => [
  //   { key: 3, label: 'set up Ask', cb: { fn: navigate, args: [steplink(id, 1)] } },
  //   { key: 4, label: 'proposal', cb: { fn: navigate, args: [steplink(id, 2)] } },
  //   { key: 5, label: 'learning materials', cb: { fn: navigate, args: [steplink(id, 3)] } },
  //   { key: 6, label: 'rewards', cb: { fn: navigate, args: [steplink(id, 4)] } }
  // ];

  // const [_steps, setSteps] = useState([...communitySteps(0), ...askSteps(0)]);

  const [_steps, setSteps] = useState([...communitySteps(0)]);

  useEffect(() => {
    const cId = community_id || communityId || 0;

    // let steps = [...communitySteps(cId), ...askSteps(cId)];

    let steps = [...communitySteps(cId)];

    if (d) {
      if (location.pathname.match('/community')) {
        steps = [...communitySteps(cId)];
      }

      // if (location.pathname.match(/^\/community\/\d+\/ask/)) {
      //   steps = [...askSteps(cId)];
      // }
    }

    setSteps(steps);
  }, [d, community_id, communityId]);

  return <StepperProvider steps={_steps}>{children}</StepperProvider>;
};

const WithCreateCommunityProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  return <CreateCommunityProvider>{children}</CreateCommunityProvider>;
};

const withCommunityStepper = <P extends object>(Component: ComponentType<P>) =>
  class WithStepper extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <WithCreateCommunityProvider>
          <CommunityStepperProvider>
            <Component {...(props as P)} />
          </CommunityStepperProvider>
        </WithCreateCommunityProvider>
      );
    }
  };

export default withCommunityStepper;
