import { Suspense, lazy } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { GuestGuard, AuthGuard, RegisterGuard, StepCommunityGuard } from 'src/components/Guards';
import BaseLayout from './layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';
import DashboardLayout from 'src/layouts/DashboardLayout';
import AskLayout from 'src/layouts/AskLayout';
import AccessGuard from 'src/components/Guards/AccessGuard';
import SubscriptionGuard from './components/Guards/SubscriptionGuard';
import WalletGuard from './components/Guards/WalletGuard';
import AcceptInviteGuard from './components/Guards/AcceptInviteGuard';
import CommunityLimitGuard from './components/Guards/CommunityLimitGuard';

export const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Status
const Status404 = Loader(lazy(() => import('src/pages/Status404')));
const ComingSoon = Loader(lazy(() => import('src/pages/ComingSoon')));

// Auth
const Register = Loader(lazy(() => import('src/pages/Auth/Register')));
const Login = Loader(lazy(() => import('src/pages/Auth/Login')));
const Recovery = Loader(lazy(() => import('src/pages/Auth/Password/Recovery')));
const VerifyMagicLink = Loader(lazy(() => import('src/pages/Auth/MagicLink/verify')));
const Reset = Loader(lazy(() => import('src/pages/Auth/Password/Reset')));
const Plan = Loader(lazy(() => import('src/pages/Auth/Plan')));

// Community
const CreateCommunity = Loader(lazy(() => import('src/pages/Community/Create')));
const InviteCommunity = Loader(lazy(() => import('src/pages/Community/Invite')));
const AcceptInvite = Loader(lazy(() => import('src/pages/Community/Invite/AcceptInvite')));
const AcceptInviteMagic = Loader(lazy(() => import('src/pages/Community/Invite/AcceptInviteMagic')));
const CommunityOverview = Loader(lazy(() => import('src/pages/Community/Overview')));
const CommunityMembership = Loader(lazy(() => import('src/pages/Community/Membership')));

// Ask
// @deprecated
// const AskForm = Loader(lazy(() => import('src/pages/Community/Ask/Form')));
const AskOverview = Loader(lazy(() => import('src/pages/Community/Ask/Overview')));
const AskAnalytics = Loader(lazy(() => import('src/pages/Community/Ask/Analytics')));

// Voxiberation
const Voxiberation = Loader(lazy(() => import('src/pages/Community/Ask/Voxiberation')));

const TownHallPage = Loader(lazy(() => import('src/pages/TownHall')));
const SettingsPage = Loader(lazy(() => import('src/pages/Settings')));
const HistoryPage = Loader(lazy(() => import('src/pages/History')));
// const WalletPage = Loader(lazy(() => import('src/pages/Wallet')));

const NotificationMobile = Loader(lazy(() => import('src/components/Header/Buttons/Notifications/NotificationMobile')));

const routes = [
  {
    path: '/',
    children: [
      {
        path: '',
        element: <Navigate to="/login" replace />
      },
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: (
          <RegisterGuard>
            <Register />
          </RegisterGuard>
        )
      },
      {
        path: 'forgot-password',
        element: <Recovery />
      },
      {
        path: 'login/:token',
        element: <VerifyMagicLink />
      },
      {
        path: 'register/:token',
        element: <VerifyMagicLink />
      },
      {
        path: 'reset-password/:token',
        element: <Reset />
      },
      {
        path: 'plan',
        element: (
          <AuthGuard>
            <SubscriptionGuard>
              <Plan />
            </SubscriptionGuard>
          </AuthGuard>
        )
      }
    ]
  },
  {
    path: '/community',
    element: (
      <AuthGuard>
        <BaseLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: (
          <StepCommunityGuard>
            <Outlet />
          </StepCommunityGuard>
        ),
        children: [
          {
            path: 'create',
            element: (
              <CommunityLimitGuard>
                <CreateCommunity />
              </CommunityLimitGuard>
            )
          },
          {
            path: ':community_id/invite',
            element: <InviteCommunity />
          }
          /* {
            path: ':community_id/ask',
            element: (
              <StepAskGuard>
                <SubscriptionGuard>
                  <AskForm />
                </SubscriptionGuard>
              </StepAskGuard>
            )
          } */
        ]
      },
      {
        // community wrapped in dashboard layout
        path: ':community_id',
        element: (
          <AccessGuard>
            <SubscriptionGuard>
              <Outlet />
            </SubscriptionGuard>
          </AccessGuard>
        ),
        children: [
          {
            path: '',
            element: <DashboardLayout />,
            children: [
              {
                path: '',
                element: <CommunityOverview />
              },
              {
                path: 'members',
                element: <CommunityMembership />
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/ask',
    element: (
      <AuthGuard>
        <AccessGuard>
          <SubscriptionGuard>
            <BaseLayout />
          </SubscriptionGuard>
        </AccessGuard>
      </AuthGuard>
    ),
    children: [
      {
        path: 'draft',
        children: [
          {
            path: ':community_id',
            element: (
              <AskLayout withHeader>
                <AskOverview draft={true} />
              </AskLayout>
            )
          }
        ]
      },
      {
        path: ':ask_id',
        element: (
          <AskLayout withHeader>
            <Outlet />
          </AskLayout>
        ),
        children: [
          {
            path: '',
            element: <Navigate to="view" replace />
          },
          {
            path: 'view',
            element: <AskOverview />
          },
          {
            path: 'voxiberation',
            element: <Voxiberation />
          },
          {
            path: 'buzz',
            element: <ComingSoon title="The buzz" />
          },
          {
            path: 'analytics',
            element: <AskAnalytics />
          }
        ]
      }
    ]
  },
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="communities" replace />
      },
      {
        path: 'communities',
        element: (
          <SubscriptionGuard>
            <TownHallPage />
          </SubscriptionGuard>
        )
      },
      /* {
        path: 'wallet',
        element: <WalletPage />
      }, */
      {
        path: 'settings',
        element: <SettingsPage />
      },
      {
        path: 'histories',
        element: <HistoryPage />
      }
    ]
  },
  {
    path: '/notifications',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '',
        element: <NotificationMobile />
      }
    ]
  },
  {
    path: '*',
    element: <Status404 />
  },
  {
    path: 'invite/:token',
    element: (
      <AcceptInviteGuard>
        <AuthGuard>
          <AcceptInviteMagic />
        </AuthGuard>
      </AcceptInviteGuard>
    )
  },
  {
    path: '/community/:community_id/join',
    element: (
      <AcceptInviteGuard>
        <AuthGuard>
          <AcceptInvite />
        </AuthGuard>
      </AcceptInviteGuard>
    )
  }
];

export default routes;
